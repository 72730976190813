import axios, { AxiosResponse } from 'axios';
import { VehicleGroupCountersLightModel } from './dataContracts/queryStack/VehicleGroupCountersLightModel';

const controllerUrl = 'api/TransportIndicators/';

export class TransportIndicatorsApiClient {
    public static GetVehicleGroupCounters = (fromTime: Date, untilTime: Date, logisticsUnits: Array<string>, searchAvailableDailyVehicles: boolean, isPerDayFilterSelected: boolean, isPerNightFilterSelected: boolean)
        : Promise<AxiosResponse<VehicleGroupCountersLightModel>> => {
        return axios.post(`${controllerUrl}GetVehicleGroupCounters?fromTime=${encodeURIComponent(fromTime.toJSON())}&untilTime=${encodeURIComponent(untilTime.toJSON())}&currentDate=${encodeURIComponent((new Date(Date.now())).toJSON())}&searchAvailableDailyVehicles=${encodeURIComponent(searchAvailableDailyVehicles)}&isPerDayFilterSelected=${encodeURIComponent(isPerDayFilterSelected)}&isPerNightFilterSelected=${encodeURIComponent(isPerNightFilterSelected)}`,
            JSON.stringify(logisticsUnits), {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            }
        })
    }
}