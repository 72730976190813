import { faSearch, faTimes, faUsers } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CloseIcon from '@mui/icons-material/Close';
import { Avatar, Box, Button, Checkbox, Dialog, DialogContent, DialogTitle, FormControlLabel, IconButton, Input, InputAdornment, Tooltip, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { CalendarComponent } from '../../../shared/components/Common/CalendarComponent';
import { DateRange } from '../../../shared/models/DateRange';
import '../../../utils/Number';
import { PlanningVehicleLightModelExtended } from '../models/PlanningVehicleLightModelExtended';
import { PlanningVehicleVisualisationLightModel } from '../services/dataContracts/queryStack/PlanningVehicleVisualisationLightModel';
import { VisualisationComponent } from './VisualisationComponent';

interface ContentHeaderComponentProps {
    isTransporterDrawerOpened: boolean,
    inputSearchVehiclesValue: string,
    inputSearchVehiclesRef: React.MutableRefObject<HTMLInputElement>,
    isHeaderBtnsEnabled: boolean,
    updateTotalFeesValues: boolean,
    planningVehiclesList: Array<PlanningVehicleLightModelExtended>,
    visualisationData: Array<PlanningVehicleVisualisationLightModel>,
    _handleOpenCloseTransporterDrawer: () => void,
    _handleChangeDateRange: (range: DateRange) => void,
    _SendConfirmationBC: () => void,
    _handleVehiclesTripsKeyPress: (text: string) => void,
    _clearSearchText: () => void,
    _setUpdateTotalFeesValues: (toUpdate: boolean) => void,
    _getVisualisationData: () => void,
    _getLabelPrice: (priceKind: string) => string,
    handlePublishVehiclesCosts: () => void,
    showOnlyWithCostsInDisagreement: boolean,
    showOnlyCanceledVehicles: boolean,
    setShowOnlyWithCostsInDisagreement: (value: boolean) => void
    setShowOnlyCanceledVehicles: (value: boolean) => void
}

interface FeesAndCostsState {
    totalTollFeesValue: number,
    totalWaitFeesValue: number,
    totalOthersFeesValue: number,
    vehicleCostsValue: number
}
export const ContentHeaderComponent = (props: ContentHeaderComponentProps) => {

    const [state, setState] = useState<FeesAndCostsState>({
        totalTollFeesValue: 0,
        totalWaitFeesValue: 0,
        totalOthersFeesValue: 0,
        vehicleCostsValue: 0
    });
    const [isOpen, setOpenPopup] = useState<boolean>(false);
    const [isDateRangeValid, setIsDateRangeValid] = useState<boolean>(true);

    useEffect(() => {
        getTotalFeesValues();
    }, []);

    useEffect(() => {
        if (props.updateTotalFeesValues) {
            getTotalFeesValues();
        }
    }, [props.updateTotalFeesValues]);

    const getTotalFeesValues = (): void => {
        const { planningVehiclesList } = props;
        let totalTollFeesValue = 0;
        let totalWaitFeesValue = 0;
        let totalOthersFeesValue = 0;
        let vehicleCostsValue = 0;

        planningVehiclesList.forEach((pv: PlanningVehicleLightModelExtended) => {
            const vehicleCosts = pv.totalPurchasePriceWithFees === null ? 0 : Number(pv.totalPurchasePriceWithFees);
            vehicleCostsValue = Number(vehicleCostsValue + vehicleCosts);

            const tollFees = Number(pv.tollFees ?? 0);
            totalTollFeesValue = Number(totalTollFeesValue + tollFees);

            const waitFees = Number(pv.waitFees ?? 0);
            totalWaitFeesValue = Number(totalWaitFeesValue + waitFees);

            const otherFees = Number(pv.otherFees ?? 0);
            totalOthersFeesValue = Number(totalOthersFeesValue + otherFees);
        });

        setState({
            ...state,
            totalTollFeesValue: totalTollFeesValue,
            totalWaitFeesValue: totalWaitFeesValue,
            totalOthersFeesValue: totalOthersFeesValue,
            vehicleCostsValue: vehicleCostsValue
        });

        props._setUpdateTotalFeesValues(false);
    }

    const handleOpenPopup = () =>{
        props._getVisualisationData();
        setOpenPopup(!isOpen);
    }

    const handleClosePopup = (reason?: string) =>{
        if (reason !== "backdropClick") {
            setOpenPopup(!isOpen);
        }
    }

    const handleShowOnlyWithCostsInDisagreementChanged = (e: React.ChangeEvent<HTMLInputElement>): void => {
        props.setShowOnlyWithCostsInDisagreement(e.target.checked);
    }

    const handleShowOnlyCanceledVehiclesChanged = (e: React.ChangeEvent<HTMLInputElement>): void => {
        props.setShowOnlyCanceledVehicles(e.target.checked);
    }

    const handleIsDateRangeValidChanged = (isValid: boolean): void => {
        setIsDateRangeValid(isValid);
    }

    const checkboxInputProps: React.InputHTMLAttributes<HTMLInputElement> = { 'aria-label': 'primary checkbox' };

    return (
        <Box display="flex" flexDirection="row" flex='wrap' justifyContent="space-between" className="content-header">
            {!props.isTransporterDrawerOpened &&
                <Box>
                    <Avatar className="btn-track">
                        <Tooltip title="Transporteurs" placement="bottom">
                            <IconButton size="small" className="btn-icon" onClick={() => props._handleOpenCloseTransporterDrawer()}>
                                <FontAwesomeIcon icon={faUsers} />
                            </IconButton>
                        </Tooltip>
                    </Avatar>
                </Box>
            }
            <CalendarComponent handlerFromChildToParent={props._handleChangeDateRange} calendarId='vehicles-management-daterange-picker' maximumNumberOfMonths={2} isValid={isDateRangeValid}
                handleIsValidChanged={handleIsDateRangeValidChanged} showNeighboringMonth='previousAndCurrent' />
            <Box>
                <Input disableUnderline className={props.inputSearchVehiclesValue.length > 2 ? 'input-search-vehicles search-text-active' : 'input-search-vehicles'} inputRef={props.inputSearchVehiclesRef}
                    endAdornment={<>
                        <InputAdornment position="end" classes={
                            {
                                root: 'input-icon-close-root'
                            }
                        }>
                            <FontAwesomeIcon icon={faTimes} onClick={() => props._clearSearchText()} />
                        </InputAdornment>
                        <InputAdornment position="end" classes={
                            {
                                root: 'input-icon-search-root'
                            }
                        }>
                            <FontAwesomeIcon icon={faSearch} className="icon-search" />
                        </InputAdornment>
                    </>}
                    id="input-search-text" placeholder="Rechercher..." onChange={(event) => props._handleVehiclesTripsKeyPress(event.target.value)} />
            </Box>
            <Box display="flex" flexDirection="column">
                <FormControlLabel
                    id="show-only-with-costs-in-disagreement"
                    control={
                        <Checkbox
                            checked={props.showOnlyWithCostsInDisagreement}
                            onChange={(e) => handleShowOnlyWithCostsInDisagreementChanged(e)}
                            color="primary"
                            inputProps={checkboxInputProps}
                        />
                    }
                    label="KO transporteur"
                    labelPlacement="start"
                />
                <FormControlLabel
                    id="show-only-canceled-vehicles"
                    control={
                        <Checkbox
                            checked={props.showOnlyCanceledVehicles}
                            onChange={(e) => handleShowOnlyCanceledVehiclesChanged(e)}
                            color="primary"
                            inputProps={checkboxInputProps}
                        />
                    }
                    label="Camion(s) annulé(s)"
                    labelPlacement="start"
                />
            </Box>
            <Box>
                <Button variant="contained" className="prev-confirm-btn" onClick={() => handleOpenPopup()}>
                    PRE-VISU CC
                </Button>
            </Box>
            <Dialog
                disableEscapeKeyDown
                onClose={(_event, reason) => handleClosePopup(reason)}
                aria-labelledby="simple-dialog-title"
                open={isOpen}
                disableEnforceFocus={true}
                className="visualisation-popup"
            >
                <DialogTitle className="visualisation-popup-title">
                    <Typography variant="h6">Pré-visualisation des confirmations de commande</Typography>
                    <IconButton aria-label="close" onClick={() => handleClosePopup()} className="visualisation-popup-close-icon">
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent className="visualisation-popup-content">
                    <VisualisationComponent _getLabelPrice={props._getLabelPrice} visualisationData={props.visualisationData} />
                </DialogContent>
            </Dialog>
            <Box>
                <Button variant="contained" className="prev-confirm-btn" onClick={() => props._SendConfirmationBC()} disabled={!props.isHeaderBtnsEnabled}>
                    CONFIRMATION DE COMMANDE
                </Button>
            </Box>
            <Box>
                <Button variant="contained" className="prev-confirm-btn" onClick={() => props.handlePublishVehiclesCosts()} disabled={!props.isHeaderBtnsEnabled}>
                    Préfacturation
                </Button>
            </Box>

            <Box display="flex" flexDirection="row" flex="nowrap" justifyContent="space-between" className="content-header-fees-section">
                <Box display="flex" flexDirection="column" justifyContent="center" className="vehicle-label">
                    <Box className="vehicle-label-value">
                        Frais camion
                    </Box>
                    <Box className="vehicle-label-title" pt={1}>
                        {Number(state.vehicleCostsValue) !== 0 ? state.vehicleCostsValue.toCurrencyString() : 'NR'}
                    </Box>
                </Box>
                <Box display="flex" flexDirection="column" justifyContent="center" className="fees-type">
                    <Box className="fees-type-label-type">
                        Péage
                    </Box>
                    <Box className="fees-type-label-value" pt={1}>
                        {Number(state.totalTollFeesValue) !== 0 ? state.totalTollFeesValue.toCurrencyString() : 'NR'}
                    </Box>
                </Box>
                <Box display="flex" flexDirection="column" justifyContent="center" className="fees-type">
                    <Box className="fees-type-label-type">
                        Attente
                    </Box>
                    <Box className="fees-type-label-value" pt={1}>
                        {Number(state.totalWaitFeesValue) !== 0 ? state.totalWaitFeesValue.toCurrencyString() : 'NR'}
                    </Box>
                </Box>
                <Box display="flex" flexDirection="column" justifyContent="center" className="fees-type border-radius">
                    <Box className="fees-type-label-type">
                        Autres
                    </Box>
                    <Box className="fees-type-label-value" pt={1}>
                        {Number(state.totalOthersFeesValue) !== 0 ? state.totalOthersFeesValue.toCurrencyString() : 'NR'}
                    </Box>
                </Box>
            </Box>
        </Box>
    );
}
