import { faCamera, faClone, faPen, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Button, CircularProgress, ClickAwayListener, Dialog, DialogActions, DialogContent, DialogTitle, MenuItemProps, Paper, PaperProps, Tooltip } from '@mui/material';
import { GridCellProps, GridRowProps } from '@progress/kendo-react-grid';
import { Menu, MenuItem } from '@progress/kendo-react-layout';
import { Popup } from '@progress/kendo-react-popup';
import { chain, cloneDeep, countBy, debounce, Dictionary, each, groupBy, isEqual, some, sortBy, uniq } from 'lodash';
import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Draggable from 'react-draggable';
import { ScaleLoader } from 'react-spinners';
import 'react-toastify/dist/ReactToastify.min.css';
import imgSendZephyr from 'src/assets/images/logoSendZephyr.png';
import imgSendZephyrGray from 'src/assets/images/logoSendZephyrGray.png';
import { SettingsProvider } from 'src/SettingsProvider';
import SimpleDialog from 'src/shared/components/Common/SimpleDialog';
import { FieldDescriptor } from 'src/shared/components/TextSearchFieldsSelector/models/FieldDescriptor';
import ToastService from 'src/ToastService';
import { DateRange } from '../../shared/models/DateRange';
import { LogisticsUnitChoice } from '../../shared/models/LogisticsUnitChoice';
import BusinessErrors from '../../utils/BusinessErrors';
import '../../utils/Date';
import { AppModule, LocalStorage, SessionStorage } from '../../utils/Storage';
import { TransportFlowHeaderComponent } from '../TransportFlow/components/TransportFlowHeaderComponent';
import { TransportFlowForm } from '../TransportFlow/TransportFlowForm';
import { ContentHeaderComponent } from './components/ContentHeaderComponent';
import { MarginDrawerComponent } from './components/MarginDrawerComponent';
import { RegularizationPreInvoiceTemplateComponent } from './components/RegularizationPreInvoiceTemplateComponent';
import { TransportSalesComponent } from './components/TransportSalesComponent';
import { GroupLabelFilter } from './models/GroupLabelFilter';
import { NewPreInvoiceRegularizationInitModelExtended } from './models/NewPreInvoiceRegularizationInitModelExtended';
import { OptionSelectFilter } from './models/OptionSelectFilter';
import { PreInvoiceCosts } from './models/PreInvoiceCosts';
import { SimpleDialogModel } from './models/SimpleDialogModel';
import { TransportSaleLightModelExtended } from './models/TransportSaleLightModelExtended';
import { CopyFlowPriceIntoInvoicePriceRequestArgs } from './services/dataContracts/controller/CopyFlowPriceIntoInvoicePriceRequestArgs';
import { CreateRegularizationPreInvoiceRequestArg } from './services/dataContracts/controller/CreateRegularizationPreInvoiceRequestArg';
import { IntegratePreInvoicesRequestArgs } from './services/dataContracts/controller/IntegratePreInvoicesRequestArgs';
import { IntegratePreInvoicesRequestArgsItem } from './services/dataContracts/controller/IntegratePreInvoicesRequestArgsItem';
import { IntegratePreInvoicesRequestArgsItemDetail } from './services/dataContracts/controller/IntegratePreInvoicesRequestArgsItemDetail';
import { PreInvoiceDetailChangeInvoicePriceRequestArgs } from './services/dataContracts/controller/PreInvoiceDetailChangeInvoicePriceRequestArgs';
import { PreInvoiceDetailChangeRemarksRequestArgs } from './services/dataContracts/controller/PreInvoiceDetailChangeRemarksRequestArgs';
import { PreInvoiceGenerateRequestArgs } from './services/dataContracts/controller/PreInvoiceGenerateRequestArgs';
import { PreInvoiceRequestArgs } from './services/dataContracts/controller/PreInvoiceRequestArgs';
import { RemovePreInvoiceRequestArgs } from './services/dataContracts/controller/RemovePreInvoiceRequestArgs';
import { TextSearchField } from './services/dataContracts/controller/TextSearchField';
import { PreInvoiceSentStatusLightModel } from './services/dataContracts/queryStack/PreInvoiceSentStatusLightModel';
import { TransportSaleCostWithMarginLightModel } from './services/dataContracts/queryStack/TransportSaleCostWithMarginLightModel';
import { TransportSaleEntityLightModel } from './services/dataContracts/queryStack/TransportSaleEntityLightModel';
import { TransportSaleLightModel } from './services/dataContracts/queryStack/TransportSaleLightModel';
import { TransportSalesApiClient } from './services/TransportSalesApiClient';
import { SizingUtilities } from './SizingUtilities';
import { propertyLabels } from './TransportSalesResources';
import './TransportSalesStyles.scss';

interface TransportSalesProps {
    role: string,
    logisticsUnits: Array<LogisticsUnitChoice>,
    logisticsUnitIds: Array<string>
}

interface TransportSalesState {
    date: DateRange,
    loading: boolean,
    loadingTransportSaleCostsMargin: boolean,
    transportSaleCostsList: Array<TransportSaleCostWithMarginLightModel>,
    totalCosts: number,
    totalInvoicePrices: number,
    totalMargins: number,
    nbLogisticsUnit: number,
    generatedPreInvoicesLoading: boolean,
    generatedPreInvoicesLogisticsUnitLabel: string,
    nbLogisticsUnitWithGeneratedPreInvoices: number,
    sentPreInvoicesLoading: boolean,
    sentPreInvoicesLogisticsUnitLabel: string,
    nbLogisticsUnitWithSendPreInvoices: number,
    generatedPreInvoicesLoadingValue: number,
    generatedPreInvoices: number,
    sentPreInvoices: number,
    nbElemsToGenerated: number,
    nbElemsToSent: number,
    toPreInvoiceOnly: boolean,
    toIntegrateOnly: boolean,
    hasSendingError: boolean,
    selectedLogisticsUnitIds: string[],
    searchText: string,
    editField: string,
    showNewDataFieldsWillLost: boolean,
    showNewDataFieldsWillLostAfterEditFlow: boolean,
    showCanceledSalesWithPriceConfirmation: boolean,
    transportSellFlowsVehiclesListAll: Array<TransportSaleLightModelExtended>,
    openMargin: boolean,
    gridPageSize: number,
    gridHeight: number,
    gridPageSizeDrawerMargin: number,
    gridHeightDrawerMargin: number,
    toastLstMessageError: string[],
    lastTimeStampGetPreInvoices: number,
    lastTimeStampGetTransportSaleCostsWithMargin: number,
    filtersSelected: Array<TextSearchField>,
    lastTimeStampFilteredSelected: number,
    contextMenuOpen: boolean,
    contextMenuOffset: Offset,
    contextMenuRow: TransportSaleLightModelExtended,
    simpleDialog: SimpleDialogModel,
    preInvoiceRegularizations: NewPreInvoiceRegularizationInitModelExtended,
    groupLabelFilter: GroupLabelFilter,
    preInvoiceCosts: PreInvoiceCosts,
    isTransportFlowFormViewOpened: boolean,
    isPerNightFilterActive: boolean,
    isPerDayFilterActive: boolean
}

interface Offset {
    left: number,
    top: number
}

interface TranslatePreInvoice {
    transportSales: Array<TransportSaleLightModelExtended>,
    preInvoiceCosts: PreInvoiceCosts
}

const GroupLabelSelectionStorageKey = 'group_labelSelection';
const LogisticsUnitsSelectedOptionsStorageKey = 'logisticsUnits_selectedOptions';
const ModuleKey = AppModule.TransportSales;

const PaperComponent = (props: PaperProps) => {
    return (
        <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'} bounds="parent">
            <Paper {...props} />
        </Draggable>
    );
}

const updateMode = "update";

export class TransportSalesView extends React.Component<TransportSalesProps, TransportSalesState> {
    _isMounted: boolean;
    today = new Date();
    inputSearchTransportSellRef: React.RefObject<HTMLInputElement>;
    path = window.location.pathname.substring(1);
    lastSelectedIndex = 0;
    enableEdit: boolean;
    preventExit: boolean;
    preventExitTimeout: ReturnType<typeof setTimeout>;
    blurTimeout: ReturnType<typeof setTimeout>;
    preInvoicesGenerationBatchSize?: number;
    preInvoicesIntegrationBatchSize?: number;
    filterOptions: Array<FieldDescriptor<TextSearchField>> = [
        { label: 'N° Flux', value: TextSearchField.transportFlowBusinessId },
        { label: 'Bénéficiaire', value: TextSearchField.beneficiaryName },
        { label: 'Payeur', value: TextSearchField.payerLabel },
        { label: 'IdMDM chantier payeur', value: TextSearchField.payerJobSiteMdmId },
        { label: 'Chef de chantier payeur', value: TextSearchField.payerJobForemanName },
        { label: 'Site départ', value: TextSearchField.senderSite },
        { label: 'Site arrivée', value: TextSearchField.receiverSite },
        { label: 'Ville arrivée', value: TextSearchField.deliveryAddressCity },
        { label: 'Agence départ', value: TextSearchField.senderAgency },
        { label: 'Agence arrivée', value: TextSearchField.receiverAgency },
        { label: 'Type camion', value: TextSearchField.vehicleType },
        { label: 'Transporteur', value: TextSearchField.transporterLabel },
        { label: 'Immatriculation camion', value: TextSearchField.licencePlate },
        { label: 'Commentaire camion', value: TextSearchField.planningVehicleComments },
        { label: 'Commentaire facturation', value: TextSearchField.preInvoiceComments }
    ];

    filterSelectOptions: Array<FieldDescriptor<OptionSelectFilter>> = [
        { label: 'A synchroniser', value: OptionSelectFilter.toPreInvoiceOnly },
        { label: 'A intégrer', value: OptionSelectFilter.toIntegrateOnly },
        { label: 'Echec intégration Zéphyr', value: OptionSelectFilter.hasSendingError }
    ];

    constructor(props: TransportSalesProps) {
        super(props);

        this.inputSearchTransportSellRef = React.createRef();
        this.enableEdit = (props.role === "ADM" || props.role === "LOG") && this.path === "TransportSales";
        const config = SettingsProvider.Get();
        this.preInvoicesGenerationBatchSize = config.preInvoicesGenerationBatchSize;
        this.preInvoicesIntegrationBatchSize = config.preInvoicesIntegrationBatchSize;

        const gridOffsetFromWindowTop = SizingUtilities.gridOffsetFromWindowTop();
        const gridOffsetFromWindowTopDrawerMargin = SizingUtilities.gridOffsetFromWindowTopDrawerMargin();
        const gridHeight = SizingUtilities.computeGridHeight(gridOffsetFromWindowTop);
        const gridHeightDrawerMargin = SizingUtilities.computeGridHeight(gridOffsetFromWindowTopDrawerMargin);
        const gridPageSize = SizingUtilities.computeGridPageSize(gridHeight, SizingUtilities.rowHeight);
        const gridPageSizeDrawerMargin = SizingUtilities.computeGridPageSize(gridHeightDrawerMargin, SizingUtilities.rowHeight);
        const groupLabelStorage = LocalStorage.GetItem(ModuleKey, GroupLabelSelectionStorageKey);

        this.state = {
            date: { start: SessionStorage.ActiveStartDate, end: SessionStorage.ActiveEndDate },
            loading: false,
            loadingTransportSaleCostsMargin: false,
            generatedPreInvoicesLoading: false,
            generatedPreInvoicesLogisticsUnitLabel: null,
            nbLogisticsUnit: 0,
            nbLogisticsUnitWithGeneratedPreInvoices: 0,
            sentPreInvoicesLoading: false,
            sentPreInvoicesLogisticsUnitLabel: null,
            nbLogisticsUnitWithSendPreInvoices: 0,
            searchText: "",
            transportSellFlowsVehiclesListAll: [],
            transportSaleCostsList: [],
            toPreInvoiceOnly: false,
            toIntegrateOnly: false,
            hasSendingError: false,
            generatedPreInvoicesLoadingValue: 0,
            nbElemsToGenerated: 0,
            nbElemsToSent: 0,
            generatedPreInvoices: 0,
            sentPreInvoices: 0,
            editField: null,
            selectedLogisticsUnitIds: [],
            showNewDataFieldsWillLost: false,
            showNewDataFieldsWillLostAfterEditFlow: false,
            showCanceledSalesWithPriceConfirmation: false,
            openMargin: false,
            gridPageSize: gridPageSize,
            gridHeight: gridHeight,
            gridPageSizeDrawerMargin: gridPageSizeDrawerMargin,
            gridHeightDrawerMargin: gridHeightDrawerMargin,
            toastLstMessageError: [],
            lastTimeStampGetPreInvoices: Date.now(),
            lastTimeStampGetTransportSaleCostsWithMargin: Date.now(),
            totalCosts: 0,
            totalInvoicePrices: 0,
            totalMargins: 0,
            filtersSelected: this.filterOptions.map(x => x.value),
            lastTimeStampFilteredSelected: Date.now(),
            contextMenuOpen: false,
            contextMenuOffset: {
                left: -1,
                top: -1
            },
            contextMenuRow: null,
            simpleDialog: {
                isDialogOpened: false,
                popupContentComponent: <></>,
                dialogTitle: ''
            },
            preInvoiceRegularizations: {
                lastAppliedRegularizationNumber: null,
                details: []
            },
            groupLabelFilter: groupLabelStorage ? (groupLabelStorage as GroupLabelFilter) : GroupLabelFilter.TransportRequestBusinessId,
            preInvoiceCosts: {
                costsToIntegrate: null,
                integratedCosts: null
            },
            isTransportFlowFormViewOpened: false,
            isPerNightFilterActive: true,
            isPerDayFilterActive: true
        };
    }

    componentDidMount() {
        this._isMounted = true;
        window.onresize = (event: UIEvent) => {
            const gridOffsetFromWindowTop = SizingUtilities.gridOffsetFromWindowTop();
            const gridOffsetFromWindowTopDrawerMargin = SizingUtilities.gridOffsetFromWindowTopDrawerMargin();
            const gridHeight = window.innerHeight - gridOffsetFromWindowTop;
            const gridHeightDrawerMargin = window.innerHeight - gridOffsetFromWindowTopDrawerMargin;
            const gridPageSize = Number((gridHeight / SizingUtilities.rowHeight).toFixed(0));
            const gridPageSizeDrawerMargin = Number((gridHeightDrawerMargin / SizingUtilities.rowHeight).toFixed(0));
            this.setState({
                gridHeight: gridHeight,
                gridPageSize: gridPageSize,
                gridHeightDrawerMargin: gridHeightDrawerMargin,
                gridPageSizeDrawerMargin: gridPageSizeDrawerMargin
            });
        }

        let logisticsUnitsSelectedOptionsStorage = LocalStorage.GetItem(ModuleKey, LogisticsUnitsSelectedOptionsStorageKey);
        const logisticsUnitsString = JSON.stringify(this.props.logisticsUnits);
        if (!isEqual(logisticsUnitsSelectedOptionsStorage, logisticsUnitsString)) {
            LocalStorage.SetItem(ModuleKey, LogisticsUnitsSelectedOptionsStorageKey, logisticsUnitsString);
            logisticsUnitsSelectedOptionsStorage = LocalStorage.GetItem(ModuleKey, LogisticsUnitsSelectedOptionsStorageKey);
        }
        if (this.props.logisticsUnits.length == 1 || logisticsUnitsSelectedOptionsStorage) {
            const storedlogisticsUnits: LogisticsUnitChoice[] = JSON.parse(logisticsUnitsSelectedOptionsStorage)
            const selectedLogisticsUnitIds = this.props.logisticsUnits.length == 1 ? [this.props.logisticsUnits[0].logisticsUnitId] : storedlogisticsUnits.map(x => x.logisticsUnitId);
            this.setState({
                selectedLogisticsUnitIds: selectedLogisticsUnitIds
            }, () => {
                this.SearchPreInvoices(this.state.date, "", this.state.filtersSelected, false, false, false, selectedLogisticsUnitIds);
                this.GetVehiclesCostsWithMargin(this.state.date, selectedLogisticsUnitIds);
            });
        }
    }

    componentDidUpdate(prevProps: TransportSalesProps, prevState: TransportSalesState) {
        if (this.state.lastTimeStampFilteredSelected > prevState.lastTimeStampFilteredSelected) {
            if (this.state.filtersSelected.length == 0) {
                ToastService.showErrorToast("Veuillez séléctionner au moins un filtre.");
            }
            //Ne lancer la relancer la recherche que s'il existe au moins 3 caractères dans le champs de recherche
            else if (this.state.searchText.length >= 3) {
                this.SearchPreInvoices(this.state.date, this.state.searchText, this.state.filtersSelected, this.state.toPreInvoiceOnly, this.state.toIntegrateOnly, this.state.hasSendingError, this.state.selectedLogisticsUnitIds);
            }
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    shouldComponentUpdate(nextProps: TransportSalesProps, nextState: TransportSalesState) {
        if (this.state.lastTimeStampGetPreInvoices > nextState.lastTimeStampGetPreInvoices
            || this.state.lastTimeStampGetTransportSaleCostsWithMargin > nextState.lastTimeStampGetTransportSaleCostsWithMargin
            || this.state.lastTimeStampFilteredSelected > nextState.lastTimeStampFilteredSelected
        )
            return false;
        return true;
    }

    handleChangeGroup = (groupFilter: GroupLabelFilter): void => {
        LocalStorage.SetItem(ModuleKey, GroupLabelSelectionStorageKey, groupFilter);
        this.SearchPreInvoices(this.state.date, this.state.searchText, this.state.filtersSelected, this.state.toPreInvoiceOnly, this.state.toIntegrateOnly, this.state.hasSendingError, this.state.selectedLogisticsUnitIds, groupFilter);
    }

    clearSearchText = (): void => {
        this.inputSearchTransportSellRef.current.value = '';
        this.setState({ searchText: "" }, () => {
            this.SearchPreInvoices(this.state.date, "", this.state.filtersSelected, this.state.toPreInvoiceOnly, this.state.toIntegrateOnly, this.state.hasSendingError, this.state.selectedLogisticsUnitIds);
        });
    }

    handleTransportSellKeyPress = debounce((searchText: string): void => {
        this.setState({ searchText: searchText }, () => {
            if (searchText.length >= 3 || searchText.length === 0) {
                this.SearchPreInvoices(this.state.date, searchText, this.state.filtersSelected, this.state.toPreInvoiceOnly, this.state.toIntegrateOnly, this.state.hasSendingError, this.state.selectedLogisticsUnitIds);
            }
        });
    }, 500);

    handleSearchPreInvoices = (searchText: string): void => {
        if (this.state.searchText != searchText) {
            this.inputSearchTransportSellRef.current.value = searchText;
            this.setState({ searchText: searchText }, () => {
                this.SearchPreInvoices(this.state.date, searchText, this.state.filtersSelected, this.state.toPreInvoiceOnly, this.state.toIntegrateOnly, this.state.hasSendingError, this.state.selectedLogisticsUnitIds);
            });
        }
    }

    GeneratePreInvoicesFromTransportFlows = (shouldForceFreshDataFromFlow: boolean): void => {
        if (this.state.transportSellFlowsVehiclesListAll.some(f => f.checked == true && f.entity == TransportSaleEntityLightModel.transportFlow
            && f.sentStatus !== PreInvoiceSentStatusLightModel.successfully && !f.regularizationNumber && f.lastWriteDate)) {
            this.setState({
                showNewDataFieldsWillLost: true
            });
        }
        else
            this.HandleConfirmGeneratePreInvoicesFromTransportFlows(shouldForceFreshDataFromFlow);
    }

    generatePreInvoiceFromTransportFlowByContextMenu = (shouldForceFreshDataFromFlow: boolean): void => {
        const { contextMenuRow } = this.state;
        if (contextMenuRow?.lastWriteDate) {
            this.setState({
                contextMenuOpen: false,
                contextMenuOffset: {
                    left: -1,
                    top: -1
                },
                showNewDataFieldsWillLost: true
            });
        }
        else
            this.HandleConfirmGeneratePreInvoicesFromTransportFlows(shouldForceFreshDataFromFlow);
    }

    HandleHideGeneratePreInvoicesFromTransportFlows = (): void => {
        this.setState({
            showNewDataFieldsWillLost: false
        });
    }

    HandleHideGeneratePreInvoicesFromTransportFlowsAfterEditFlow = (): void => {
        this.setState({
            showNewDataFieldsWillLostAfterEditFlow: false
        });
    }

    getBusinessId = (extraResult: Map<number, string>, index: number): string => {
        return extraResult[index];
    }

    getSelectedPreInvoicesToIntegrate = (): [Array<TransportSaleLightModelExtended>, Array<TransportSaleLightModelExtended>] => {
        const { transportSellFlowsVehiclesListAll, contextMenuRow } = this.state;
        let transportFlowsSelected: Array<TransportSaleLightModelExtended> = [];

        //Cas de génération via le menuContextuel
        if (contextMenuRow) {
            transportFlowsSelected.push(contextMenuRow);
        }
        //Cas de génération via le boutton de génération
        else {
            transportFlowsSelected = transportSellFlowsVehiclesListAll
                .filter(f => f.checked
                    && f.entity === TransportSaleEntityLightModel.transportFlow
                    && f.sentStatus !== PreInvoiceSentStatusLightModel.successfully);
        }

        const details = transportSellFlowsVehiclesListAll
            .filter(d => d.entity === TransportSaleEntityLightModel.planningVehicle
                && d.invoicePrice
                && transportFlowsSelected.some(x => x.preInvoiceId === d.preInvoiceId));

        return [transportFlowsSelected, details];
    }

    sendIntegrationPreInvoices = async (): Promise<void> => {
        const details = this.getSelectedPreInvoicesToIntegrate()[1];

        for (const detail of details ?? []) {
            if (detail.planningVehicleStatus.isCanceled &&
                detail.planningVehicleStatus.saleIsCanceled &&
                detail.invoicePrice) {
                this.setState({ showCanceledSalesWithPriceConfirmation: true });
                return;
            }
        }

        await this.handleSendIntegrationPreInvoices();
    }

    handleHideSendIntegrationPreInvoices = () => {
        this.setState({ showCanceledSalesWithPriceConfirmation: false });
    }

    handleSendIntegrationPreInvoices = async (hasConfirmedCanceledSalesWithNonZeroPrice?: boolean): Promise<void> => {
        const [selectedInvoices, selectedDetails] = this.getSelectedPreInvoicesToIntegrate();
        const transportFlowsToSend = selectedInvoices.filter(f => f.lastWriteDate != null);
        const hasIgnoredFlows = transportFlowsToSend.length < selectedInvoices.length;

        const invoices: Array<IntegratePreInvoicesRequestArgsItem> = [];
        transportFlowsToSend.forEach((f: TransportSaleLightModelExtended) => {
            const planningVehiclesOfSale: Array<TransportSaleLightModelExtended> = selectedDetails.filter(p => p.preInvoiceId === f.preInvoiceId);
            const details: Array<IntegratePreInvoicesRequestArgsItemDetail> = [];
            planningVehiclesOfSale.forEach(v => {
                details.push({
                    planningVehicleId: v.planningVehicleId,
                    vehicleTypeId: v.vehicleTypeId,
                    invoicePrice: v.invoicePrice ?? 0,
                    deliveredNumberOfTurns: v.deliveredNumberOfTurns,
                    deliveredQuantity: v.deliveredQuantity,
                    planningVehicleSaleIsCanceled: v.planningVehicleStatus?.saleIsCanceled,
                    hasConfirmedCanceledSalesWithNonZeroPrice: hasConfirmedCanceledSalesWithNonZeroPrice
                });
            });

            invoices.push({
                beneficiaryName: f.beneficiaryName,
                beneficiaryContactUserName: f.beneficiaryContactUserName,
                beneficiaryIsExternal: f.beneficiaryIsExternal,
                logisticsUnitId: f.flowLogisticsUnitId,
                logisticsAgencyId: f.flowLogisticsAgencyId,
                logisticsBusinessUnitId: f.flowLogisticsBusinessUnitId,
                flowProductCode: f.flowProductCode,
                payerAgencyId: f.flowPayerAgencyId,
                payerProductionSiteBusinessUnitId: f.flowPayerProductionSiteBusinessUnitId,
                payerZephyrJobCode: f.flowPayerZephyrJobCode,
                payerBeneficiaryContactUserName: f.payerBeneficiaryContactUserName,
                payerBeneficiaryName: f.payerBeneficiaryName,
                payerEntityIsAgency: f.payerEntity == "Agency",
                payerJobSiteMdmId: f.payerJobSiteMdmId,
                payerBeneficiaryIsExternal: f.payerBeneficiaryIsExternal,
                arrivalTimeAtReceiverSite: f.arrivalTimeAtReceiverSite,
                flowVehicleTypeId: f.flowVehicleTypeId,
                deliveredQuantity: f.deliveredQuantity ?? 0,
                deliveredNumberOfTurns: f.deliveredNumberOfTurns ?? 0,
                invoicePrice: f.invoicePrice ?? 0,
                billedTransportPriceKind: f.transportSellPriceKindBase,
                planningId: f.planningId,
                preInvoiceId: f.preInvoiceId,
                versionOfAggregate: f.versionOfAggregate,
                transportFlowId: f.transportFlowId,
                transportFlowBusinessId: f.businessId,
                details: details
            });
        });

        const groupedPreInvoices = groupBy(invoices, x => x.logisticsUnitId);
        for (const [logisticsUnitId, invoicesGroup] of Object.entries(groupedPreInvoices)) {
            const nbElemAll: number = invoicesGroup.length;
            const logisticsUnitLabel = this.props.logisticsUnits.find(x => x.logisticsUnitId === logisticsUnitId)?.label;
            this.setState({
                sentPreInvoicesLoading: true,
                sentPreInvoicesLogisticsUnitLabel: logisticsUnitLabel,
                nbElemsToSent: nbElemAll,
                nbLogisticsUnit: Object.entries(groupedPreInvoices).length,
                nbLogisticsUnitWithSendPreInvoices: this.state.nbLogisticsUnitWithSendPreInvoices + 1,
                sentPreInvoices: 0,
                contextMenuOpen: false,
                contextMenuOffset: {
                    left: -1,
                    top: -1
                },
                contextMenuRow: null,
                showCanceledSalesWithPriceConfirmation: false
            });

            let finished = false;
            let indexStart = 0;
            const preInvoicesIntegrationBatchSize: number = this.preInvoicesIntegrationBatchSize;
            let indexEnd: number = nbElemAll > preInvoicesIntegrationBatchSize ? preInvoicesIntegrationBatchSize : nbElemAll;

            while (!finished) {
                if (indexStart >= nbElemAll) {
                    finished = true;
                }
                else {
                    const tInvoices = invoicesGroup.slice(indexStart, indexEnd);
                    const invoiceSendIntegrateToZephyrRequest: IntegratePreInvoicesRequestArgs = {
                        logisticsUnitId: logisticsUnitId,
                        invoiceRequests: tInvoices
                    };
                    const { toastLstMessageError } = this.state;
                    let newLstMessageError: string[] = [];

                    await TransportSalesApiClient.SendIntegrationPreInvoices(invoiceSendIntegrateToZephyrRequest)
                        .then(res => {
                            const errors = BusinessErrors.GetEx(res.data, this.getBusinessId);

                            if (errors.length > 0) {
                                newLstMessageError = errors;
                            }
                        })
                        .finally(() => {
                            const nbElemDone = indexEnd;
                            indexStart = indexStart + preInvoicesIntegrationBatchSize;
                            indexEnd = indexStart + preInvoicesIntegrationBatchSize;
                            toastLstMessageError.push(...newLstMessageError);
                            this.setState({
                                toastLstMessageError: toastLstMessageError,
                                sentPreInvoices: nbElemDone
                            });
                        });
                }
            }
        }

        let { toastLstMessageError } = this.state;
        toastLstMessageError = uniq(toastLstMessageError);

        if (hasIgnoredFlows) {
            toastLstMessageError.push("Les Flux non synchronisés n'ont pas été envoyés à Zéphyr");
        }

        if (toastLstMessageError.length > 0) {
            ToastService.showErrorToast("Envoi BL Zephyr impossible:", toastLstMessageError);
        }

        this.setState({
            sentPreInvoicesLoading: false,
            sentPreInvoicesLogisticsUnitLabel: null,
            nbElemsToSent: 0,
            sentPreInvoices: 0,
            nbLogisticsUnit: 0,
            nbLogisticsUnitWithSendPreInvoices: 0,
            toastLstMessageError: []
        }, () => {
            const selectedIds = invoices.map(x => x.preInvoiceId ?? `${x.planningId}-${x.transportFlowId}`);
            this.SearchPreInvoices(this.state.date, this.state.searchText, this.state.filtersSelected, this.state.toPreInvoiceOnly, this.state.toIntegrateOnly, this.state.hasSendingError, this.state.selectedLogisticsUnitIds, null, selectedIds);
        });
    }

    HandleConfirmGeneratePreInvoicesFromTransportFlows = async (shouldForceFreshDataFromFlow: boolean): Promise<void> => {
        let transportFlowToPreInvoice: Array<TransportSaleLightModelExtended> = [];
        //Cas d'une confirmation de génération de PreInvoice via le menu contextuel
        if (this.state.contextMenuRow) {
            transportFlowToPreInvoice.push(this.state.contextMenuRow);
        }
        //Cas d'une confirmation via le boutton de prise de photo
        else {
            transportFlowToPreInvoice = this.state.transportSellFlowsVehiclesListAll.filter(f => f.checked == true && f.entity == TransportSaleEntityLightModel.transportFlow
                && f.sentStatus !== PreInvoiceSentStatusLightModel.successfully && !f.regularizationNumber);
        }

        const preInvoices: Array<PreInvoiceRequestArgs> = [];
        transportFlowToPreInvoice.forEach((f: TransportSaleLightModelExtended) => {
            preInvoices.push(({
                planningId: f.planningId,
                planningDate: f.planningDate,
                transportFlowId: f.transportFlowId,
                transportFlowBusinessId: f.businessId,
                transportRequestId: f.transportRequestId,
                transportRequestBusinessId: f.transportRequestBusinessId,
                transportRequestIsHidden: f.transportRequestIsHidden,
                beneficiaryName: f.beneficiaryName,
                beneficiaryContactUserName: f.beneficiaryContactUserName,
                beneficiaryIsExternal: f.beneficiaryIsExternal,
                payerEntity: f.payerEntity,
                payerIdentifier: f.payerIdentifier,
                payerLabel: f.payerLabel,
                flowPriority: f.priorityBase,
                plannedNumberOfTurns: f.plannedNumberOfTurns,
                plannedQuantity: f.plannedQuantity,
                deliveredNumberOfTurns: f.deliveredNumberOfTurns,
                deliveredQuantity: f.deliveredQuantity,
                transportSellPriceKind: f.transportSellPriceKindBase,
                flowPrice: f.flowPrice,
                senderSiteResolutionLabel: f.senderSiteResolutionLabel,
                senderSiteResolutionEntityTypes: f.senderSiteResolutionEntityTypes,
                senderSiteResolutionIdentifiers: f.senderSiteResolutionIdentifiers,
                senderSiteResolutionKind: f.senderSiteResolutionKind,
                receiverSiteResolutionLabel: f.receiverSiteResolutionLabel,
                receiverSiteResolutionEntityTypes: f.receiverSiteResolutionEntityTypes,
                receiverSiteResolutionIdentifiers: f.receiverSiteResolutionIdentifiers,
                receiverSiteResolutionKind: f.receiverSiteResolutionKind,
                deliveryAddressCity: f.deliveryAddressCity,
                preInvoiceId: f.preInvoiceId,
                versionOfAggregate: f.versionOfAggregate,
                logisticsUnitId: f.flowLogisticsUnitId,
                logisticsUnitLabel: f.logisticsUnitLabel,
                logisticsBusinessUnitId: f.flowLogisticsBusinessUnitId,
                logisticsAgencyId: f.flowLogisticsAgencyId,
                payerAgencyId: f.flowPayerAgencyId,
                payerProductionSiteBusinessUnitId: f.flowPayerProductionSiteBusinessUnitId,
                payerZephyrJobCode: f.flowPayerZephyrJobCode,
                payerJobSiteMdmId: f.payerJobSiteMdmId,
                payerBeneficiaryIsExternal: f.payerBeneficiaryIsExternal,
                payerBeneficiaryContactUserName: f.payerBeneficiaryContactUserName,
                payerBeneficiaryName: f.payerBeneficiaryName,
                vehicleTypeId: f.flowVehicleTypeId,
                arrivalTimeAtReceiverSite: f.arrivalTimeAtReceiverSite,
                shouldForceFreshDataFromFlow: shouldForceFreshDataFromFlow,
                flowProductCode: f.flowProductCode
            }))
        });

        const groupedPreInvoices = groupBy(preInvoices, x => x.logisticsUnitId);
        for (const [logisticsUnitId, preInvoicesGroup] of Object.entries(groupedPreInvoices)) {
            const nbElemAll: number = preInvoicesGroup.length;
            const logisticsUnitLabel = this.props.logisticsUnits.find(x => x.logisticsUnitId === logisticsUnitId)?.label;
            this.setState({
                generatedPreInvoicesLoading: true,
                generatedPreInvoicesLogisticsUnitLabel: logisticsUnitLabel,
                nbLogisticsUnit: Object.entries(groupedPreInvoices).length,
                nbLogisticsUnitWithGeneratedPreInvoices: this.state.nbLogisticsUnitWithGeneratedPreInvoices + 1,
                generatedPreInvoicesLoadingValue: 0,
                nbElemsToGenerated: nbElemAll,
                generatedPreInvoices: 0,
                showNewDataFieldsWillLost: false,
                contextMenuOpen: false,
                contextMenuRow: null,
                contextMenuOffset: {
                    left: -1,
                    top: -1
                }
            });

            let finished = false;
            let indexStart = 0;
            const preInvoicesGenerationBatchSize: number = this.preInvoicesGenerationBatchSize;
            let indexEnd: number = nbElemAll > preInvoicesGenerationBatchSize ? preInvoicesGenerationBatchSize : nbElemAll;

            while (!finished) {
                if (indexStart >= nbElemAll) {
                    finished = true;
                }
                else {
                    const tPreInvoices = preInvoicesGroup.slice(indexStart, indexEnd);
                    const preInvoiceGenerateRequest: PreInvoiceGenerateRequestArgs = {
                        logisticsUnitId: logisticsUnitId,
                        preInvoiceRequests: tPreInvoices
                    };

                    const { toastLstMessageError } = this.state;
                    let newLstMessageError: string[] = [];

                    await TransportSalesApiClient.GeneratePreInvoicesFromTransportFlows(preInvoiceGenerateRequest)
                        .then(res => {
                            const errors = BusinessErrors.GetEx(res.data, this.getBusinessId);

                            if (errors.length > 0) {
                                newLstMessageError = errors;
                            }
                        })
                        .finally(() => {
                            const nbElemDone = indexEnd;
                            indexStart = indexStart + preInvoicesGenerationBatchSize;
                            indexEnd = indexStart + preInvoicesGenerationBatchSize;
                            toastLstMessageError.push(...newLstMessageError);

                            this.setState({
                                generatedPreInvoicesLoadingValue: ((nbElemDone * 100) / nbElemAll),
                                generatedPreInvoices: nbElemDone,
                                toastLstMessageError: toastLstMessageError
                            });
                        });
                }
            }
        }

        let { toastLstMessageError } = this.state;

        toastLstMessageError = uniq(toastLstMessageError);

        if (toastLstMessageError.length > 0) {
            ToastService.showErrorToast("Une ou plusieurs erreur(s) rencontrée(s):", toastLstMessageError);
        }

        this.setState({
            contextMenuRow: null,
            generatedPreInvoicesLoading: false,
            generatedPreInvoicesLogisticsUnitLabel: null,
            generatedPreInvoicesLoadingValue: 0,
            nbElemsToGenerated: 0,
            generatedPreInvoices: 0,
            nbLogisticsUnit: 0,
            nbLogisticsUnitWithGeneratedPreInvoices: 0
        }, () => {
            const selectedIds = preInvoices.map(x => x.preInvoiceId ?? `${x.planningId}-${x.transportFlowId}`);
            this.SearchPreInvoices(this.state.date, this.state.searchText, this.state.filtersSelected, this.state.toPreInvoiceOnly, this.state.toIntegrateOnly, this.state.hasSendingError, this.state.selectedLogisticsUnitIds, null, selectedIds);
            this.GetVehiclesCostsWithMargin(this.state.date, this.state.selectedLogisticsUnitIds);
        });
    }

    refreshComputedCells = (preInvoice: TransportSaleLightModelExtended): void => {
        const { transportSellFlowsVehiclesListAll } = this.state;
        const planningVehiclesFromTransportFlow = transportSellFlowsVehiclesListAll.filter(e => e.entity == TransportSaleEntityLightModel.planningVehicle
            && e.preInvoiceId == preInvoice.preInvoiceId);

        const transportFlow = transportSellFlowsVehiclesListAll.find(e => e.entity == TransportSaleEntityLightModel.transportFlow && e.preInvoiceId == preInvoice.preInvoiceId);
        const groupKey = transportFlow.groupKey;
        const planningVehiclesFromTransportRequest = transportSellFlowsVehiclesListAll.filter(e => e.entity == TransportSaleEntityLightModel.planningVehicle
            && e.groupKey == groupKey);
        const transportRequest = transportSellFlowsVehiclesListAll.find(e => e.entity == TransportSaleEntityLightModel.transportRequest
            && e.groupKey == groupKey);
        const sumSellFromFlow = this.sumTransportSellPrice(planningVehiclesFromTransportFlow, 'invoicePrice') ?? 0;
        const sumSellFromRequest = this.sumTransportSellPrice(planningVehiclesFromTransportRequest, 'invoicePrice') ?? 0;
        transportFlow.invoicePrice = sumSellFromFlow;

        if (transportRequest) {
            transportRequest.invoicePrice = sumSellFromRequest;
        }
    }

    translateVehiclesCosts = (vehicleCost: TransportSaleCostWithMarginLightModel): void => {
        vehicleCost.invoicePrice = vehicleCost.invoicePrice ? Number(vehicleCost.invoicePrice.toFixed(2)) : 0;
        vehicleCost.totalCosts = vehicleCost.totalCosts ? Number(vehicleCost.totalCosts.toFixed(2)) : 0;
        vehicleCost.margin = vehicleCost.margin ? Number(vehicleCost.margin.toFixed(2)) : 0;
    }

    GetVehiclesCostsWithMargin = (date: DateRange, selectedLogisticsUnitIds: string[], needRefreshPreInvoiceCosts?: boolean): void => {
        if (selectedLogisticsUnitIds.length > 0) {
            const fromTime = date.start;
            const untilTime = date.end;
            //var shouldSelectAllFlows = toIntegrateOnly ? true : false;

            this.setState({
                loadingTransportSaleCostsMargin: true
            });

            const currentTimeStamp = Date.now();

            TransportSalesApiClient.GetVehiclesCostsWithMargin(200, 0, fromTime, untilTime, selectedLogisticsUnitIds)
                .then(res => {
                    if (this._isMounted) {
                        if (currentTimeStamp < this.state.lastTimeStampGetTransportSaleCostsWithMargin)
                            return;

                        let totalInvoicePrices = 0;
                        let totalCosts = 0;
                        let totalMargins = 0;
                        res.data.forEach((el: TransportSaleCostWithMarginLightModel) => {
                            this.translateVehiclesCosts(el);
                            totalInvoicePrices += el.invoicePrice;
                            totalCosts += el.totalCosts;
                            totalMargins += el.margin;
                        });

                        if (needRefreshPreInvoiceCosts) {
                            const { transportSellFlowsVehiclesListAll } = this.state;
                            const allFlows = transportSellFlowsVehiclesListAll.filter(e => e.entity === TransportSaleEntityLightModel.transportFlow);
                            this.setState({
                                preInvoiceCosts: {
                                    integratedCosts: this.sumTransportSellPrice(allFlows.filter(e => e.sentStatus === PreInvoiceSentStatusLightModel.successfully), 'invoicePrice') ?? 0,
                                    costsToIntegrate: this.sumTransportSellPrice(allFlows.filter(e => e.sentStatus !== PreInvoiceSentStatusLightModel.successfully), 'invoicePrice') ?? 0,
                                },
                                transportSaleCostsList: res.data,
                                totalInvoicePrices: totalInvoicePrices,
                                totalCosts: totalCosts,
                                totalMargins: totalMargins
                            });
                        }
                        else {
                            this.setState({
                                transportSaleCostsList: res.data,
                                totalInvoicePrices: totalInvoicePrices,
                                totalCosts: totalCosts,
                                totalMargins: totalMargins
                            });
                        }
                    }
                })
                .finally(() => {
                    if (this._isMounted) {
                        this.setState({
                            loadingTransportSaleCostsMargin: false,
                            lastTimeStampGetTransportSaleCostsWithMargin: currentTimeStamp
                        });
                    }
                });
        }
    }

    SearchPreInvoices = (date: DateRange, searchText: string, searchTextFields: Array<TextSearchField>, toPreInvoiceOnly: boolean, toIntegrateOnly: boolean, hasSendingError: boolean, selectedLogisticsUnitIds: string[], groupLabelFilter?: GroupLabelFilter, selectedIds?: Array<string>): void => {
        if (selectedLogisticsUnitIds.length > 0 && searchTextFields.length > 0) {
            const fromTime = date.start;
            const untilTime = date.end;
            const newGroupLabelFilter = groupLabelFilter ?? this.state.groupLabelFilter;

            this.setState({
                loading: true,
                groupLabelFilter: newGroupLabelFilter
            });
            const currentTimeStamp = Date.now();

            TransportSalesApiClient.SearchPreInvoices(200, 0, searchText, searchTextFields, fromTime, untilTime, toPreInvoiceOnly, toIntegrateOnly, hasSendingError, selectedLogisticsUnitIds)
                .then(res => {
                    if (this._isMounted) {
                        if (currentTimeStamp < this.state.lastTimeStampGetPreInvoices)
                            return;

                        const preInvoiceCosts: PreInvoiceCosts = {
                            costsToIntegrate: null,
                            integratedCosts: null
                        };
                        const translatePreInvoices = this.translatePreInvoices(res.data, newGroupLabelFilter, preInvoiceCosts, selectedIds, this.state);
                        this.setState({
                            transportSellFlowsVehiclesListAll: translatePreInvoices.transportSales,
                            preInvoiceCosts: translatePreInvoices.preInvoiceCosts
                        });
                    }
                })
                .finally(() => {
                    if (this._isMounted) {
                        this.setState({
                            loading: false,
                            lastTimeStampGetPreInvoices: currentTimeStamp
                        });
                    }
                });
        }
    }

    translatePreInvoices = (transportSales: Array<TransportSaleLightModel>, groupLabelFilter: GroupLabelFilter, preInvoiceCosts: PreInvoiceCosts, selectedIds: Array<string>, state: TransportSalesState): TranslatePreInvoice => {
        const groupedTransportSales = groupBy(transportSales, groupLabelFilter);
        const sortedGroupedtransportSalesKeys = sortBy(Object.keys(groupedTransportSales), t => t);

        //let totalCostsToIntegrate = 0;
        //let totalIntegratedCosts = 0;

        const transportSellFlowsVehiclesList: Array<TransportSaleLightModelExtended> = [];
        each(sortedGroupedtransportSalesKeys, rowKey => {
            const flows: Array<TransportSaleLightModelExtended> = groupedTransportSales[rowKey];

            const elemFlows = flows.filter(e => e.entity == TransportSaleEntityLightModel.transportFlow);
            const flowPrice = this.sumTransportSellPrice(elemFlows, 'flowPrice');
            const groupKey = groupLabelFilter === GroupLabelFilter.TransportRequestBusinessId
                ? flows[0].transportRequestId
                : groupLabelFilter === GroupLabelFilter.ReceiverSiteResolutionLabel
                    ? flows[0].receiverSiteResolutionLabel
                    : groupLabelFilter === GroupLabelFilter.PayerJobSiteMdmId
                        ? flows[0].payerJobSiteMdmId
                        : flows[0].payerLabel;

            const isTransportRequestHidden = groupLabelFilter === GroupLabelFilter.TransportRequestBusinessId ? flows[0].transportRequestIsHidden : false;

            const businessId = isTransportRequestHidden
                ? null
                : groupLabelFilter === GroupLabelFilter.TransportRequestBusinessId
                    ? flows[0].transportRequestBusinessId
                    : groupKey;

            const transportSale: TransportSaleLightModelExtended = {
                transportRequestId: isTransportRequestHidden ? null : flows[0].transportRequestId,
                businessId: businessId,
                isSearchTextMatch: false,
                transportFlowId: null,
                transportFlowBusinessId: null,
                flowPriority: null,
                planningDate: null,
                vehicleTypeId: null,
                vehicleTypeLabel: null,
                plannedNumberOfTurns: isTransportRequestHidden ? null : this.sumTransportSellPrice(elemFlows, 'plannedNumberOfTurns'),
                deliveredNumberOfTurns: isTransportRequestHidden ? null : this.sumTransportSellPrice(elemFlows, 'deliveredNumberOfTurns'),
                deliveredQuantity: isTransportRequestHidden ? null : this.sumTransportSellPrice(elemFlows, 'deliveredQuantity'),
                plannedQuantity: isTransportRequestHidden ? null : this.sumTransportSellPrice(elemFlows, 'plannedQuantity'),
                beneficiaryName: null,
                beneficiaryContactUserName: null,
                beneficiaryIsExternal: null,
                payerIdentifier: null,
                payerLabel: null,
                payerEntity: null,
                payerBeneficiaryContactUserName: null,
                payerBeneficiaryName: null,
                payerJobSiteMdmId: null,
                transportSellPriceKind: null,
                flowPrice: isTransportRequestHidden ? null : flowPrice,
                entity: TransportSaleEntityLightModel.transportRequest,
                transportRequestIsHidden: null,
                transportRequestBusinessId: isTransportRequestHidden ? null : flows[0].transportRequestBusinessId,
                transporter: null,
                lastWriteDate: null,
                hasAlreadyPreInvoices: false,
                planningVehicleComments: null,
                preInvoiceComments: null,
                planningVehicleId: null,
                senderSiteResolutionEntityTypes: null,
                senderSiteResolutionIdentifiers: null,
                senderSiteResolutionKind: null,
                senderSiteResolutionLabel: null,
                receiverSiteResolutionEntityTypes: null,
                receiverSiteResolutionIdentifiers: null,
                receiverSiteResolutionKind: null,
                receiverSiteResolutionLabel: null,
                deliveryAddressCity: null,
                preInvoiceId: null,
                versionOfAggregate: 0,
                checked: false,
                flowPayerAgencyId: null,
                flowPayerProductionSiteBusinessUnitId: null,
                flowLogisticsUnitId: null,
                flowLogisticsAgencyId: null,
                flowLogisticsBusinessUnitId: null,
                flowVehicleTypeId: null,
                flowPayerZephyrJobCode: null,
                flowProductCode: null,
                sendingError: null,
                sentStatus: null,
                zephyrReceiptId: null,
                hasPendingRegularization: false,
                flowStatus: null,
                payerJobForemanName: null,
                groupKey: groupKey,
                logisticsUnitLabel: null,
                licencePlate: null,
                planningVehicleStatus: null
            }

            transportSellFlowsVehiclesList.push(transportSale);

            let flowPriceTransportRequest = 0;
            let invoicePriceTransportRequest = 0;

            const sortedTransportFlows = flows.sort((a, b) => a.planningId - b.planningId || a.transportFlowBusinessId.localeCompare(b.transportFlowBusinessId) || a.regularizationNumber - b.regularizationNumber);

            const groupedTransportFlows: Dictionary<TransportSaleLightModelExtended[]> = groupBy(sortedTransportFlows, x => x.preInvoiceId ?? `${x.planningId}-${x.transportFlowId}`);

            let nbFlowsSelected = 0;
            each(groupedTransportFlows, (flowsPlanningVehicles, groupTransportFlowId) => {
                const groupsByPlanningDate = groupBy(flowsPlanningVehicles, x => x.planningId);

                each(groupsByPlanningDate, (flowsPlanningVehiclesGroupedByDate, planningDate) => {
                    const planningVehicles: Array<TransportSaleLightModelExtended> = flowsPlanningVehiclesGroupedByDate.filter(x => x.entity == TransportSaleEntityLightModel.planningVehicle);
                    const planningVehiclesSorted: Array<TransportSaleLightModelExtended> = sortBy(planningVehicles, f => [f.vehicleTypeLabel, f.businessId]);
                    const transportFlow: TransportSaleLightModelExtended = flowsPlanningVehiclesGroupedByDate.find(x => x.entity == TransportSaleEntityLightModel.transportFlow);
                    const { ...transportFlowByDate } = transportFlow;
                    flowPriceTransportRequest += transportFlowByDate.flowPrice;

                    if (planningVehiclesSorted.length == 0) {
                        this.translateTransportSellLightModel(transportFlow, groupKey);
                        transportSellFlowsVehiclesList.push(transportFlow);
                    }
                    else {
                        transportFlowByDate.planningId = Number(planningDate);
                        transportFlowByDate.priorityBase = transportFlowByDate.flowPriority;
                        transportFlowByDate.transportSellPriceKindBase = transportFlowByDate.transportSellPriceKind;

                        if (planningVehiclesSorted.length > 0) {
                            transportFlowByDate.transportSellPriceKind = transportFlowByDate.transportSellPriceKind == "PerTimePeriod" ? this.getLabelFromDict('TransportSellPriceKind', transportFlowByDate.transportSellPriceKind) :
                                transportFlowByDate.transportSellPriceKind == "PerTon" ? (this.getLabelFromDict('TransportSellPriceKind', transportFlowByDate.transportSellPriceKind) + " / " + (planningVehiclesSorted[0].flowUnitPrice ? planningVehiclesSorted[0].flowUnitPrice : 0).toCurrencyString()) :
                                    transportFlowByDate.transportSellPriceKind == "PerTurn" ? (this.getLabelFromDict('TransportSellPriceKind', transportFlowByDate.transportSellPriceKind) + " / " + (planningVehiclesSorted[0].flowUnitPrice ? planningVehiclesSorted[0].flowUnitPrice : 0).toCurrencyString()) : null;
                        }
                        else {
                            transportFlowByDate.transportSellPriceKind = this.getLabelFromDict('TransportSellPriceKind', transportFlowByDate.transportSellPriceKind);
                        }

                        transportFlowByDate.flowPriority = this.getLabelFromDict('TransportPriority', transportFlowByDate.flowPriority);
                        transportFlowByDate.hasAlreadyPreInvoices = planningVehiclesSorted.filter(pv => pv.lastWriteDate).length == planningVehiclesSorted?.length;
                        transportFlowByDate.sentDate = planningVehiclesSorted.length > 0 ? planningVehiclesSorted[0].sentDate : null;
                        transportFlowByDate.groupKey = groupKey;

                        if (selectedIds && selectedIds.indexOf(groupTransportFlowId) !== -1) {
                            transportFlowByDate.checked = true;
                            nbFlowsSelected++;
                        }

                        //if (transportFlowByDate.hasAlreadyPreInvoices) {
                        //    if (transportFlowByDate.sentStatus === PreInvoiceSentStatusLightModel.successfully) {
                        //        totalIntegratedCosts += transportFlowByDate.invoicePrice ?? 0;
                        //    }
                        //    else {
                        //        totalCostsToIntegrate += transportFlowByDate.invoicePrice ?? 0;
                        //    }
                        //}

                        if (transportFlowByDate.regularizationNumber) {
                            transportFlowByDate.plannedNumberOfTurns = null;
                            transportFlowByDate.deliveredNumberOfTurns = null;
                        }

                        transportSellFlowsVehiclesList.push(transportFlowByDate);

                        planningVehiclesSorted.forEach(pv => {
                            pv.planningDate = null;
                            pv.lastWriteDate = null;
                            pv.sentDate = null;
                            pv.preInvoiceRemarksBase = pv.preInvoiceComments;
                            pv.preInvoiceBillingPriceBase = pv.invoicePrice;
                            pv.transportSellPriceKindBase = transportFlowByDate.transportSellPriceKindBase;
                            pv.payerLabel = null;
                            pv.payerJobSiteMdmId = null;
                            pv.receiverSiteResolutionLabel = null;
                            pv.groupKey = groupKey;
                            if (pv.regularizationNumber) {
                                pv.plannedQuantity = null;
                                pv.plannedNumberOfTurns = null;
                                pv.deliveredQuantity = null;
                                pv.deliveredNumberOfTurns = null;
                                pv.planningVehicleComments = null;
                            }

                            invoicePriceTransportRequest += pv.invoicePrice;

                            pv.transportFlow = { ...transportFlowByDate };
                            transportSellFlowsVehiclesList.push(pv);
                        });
                    }
                });
            });
            transportSale.invoicePrice = invoicePriceTransportRequest;
            if (transportSale.businessId) {
                transportSale.flowPrice = flowPriceTransportRequest;
            }

            if (nbFlowsSelected > 0 && Object.keys(groupedTransportFlows).length === nbFlowsSelected) {
                transportSale.checked = true;
            }
        });

        //preInvoiceCosts = {
        //    costsToIntegrate: totalCostsToIntegrate,
        //    integratedCosts: totalIntegratedCosts
        //};

        const planningVehicles = transportSellFlowsVehiclesList.filter(e => e.entity == TransportSaleEntityLightModel.planningVehicle);

        const pvHavingDifferentDeliveriesWithSameForeman = chain(planningVehicles)
            .groupBy(pv => `${pv.transportFlow.planningId}_${pv.businessId}_${pv.transportFlow.beneficiaryName?.toLowerCase().removeDiacritics()}`)
            .filter(items => items.length > 1)
            .flatMap()
            .value();

        pvHavingDifferentDeliveriesWithSameForeman.forEach(d => {
            d.hasDifferentDeliveriesWithSameForeman = true;
        });

        const pvHavingDifferentDeliveriesWithSameReceiverSite = chain(planningVehicles)
            .groupBy(pv => `${pv.transportFlow.planningId}_${pv.businessId}_${pv.transportFlow.receiverSiteResolutionLabel?.toLowerCase().removeDiacritics()}`)
            .filter(items => items.length > 1)
            .flatMap()
            .groupBy(pv => pv.planningVehicleId)
            .value();

        each(pvHavingDifferentDeliveriesWithSameReceiverSite, group => {
            group.forEach(d => {
                d.hasDifferentDeliveriesWithSameReceiverSite = true;
            });
        });

        const pvHavingDifferentDeliveries = chain(planningVehicles)
            .groupBy(pv => `${pv.transportFlow.planningId}_${pv.businessId}`)
            .filter(items => items.length > 1)
            .flatMap()
            .groupBy(pv => pv.planningVehicleId)
            .value();

        planningVehicles.forEach(d => {
            const pvHavingDifferentDeliveriesWithSameReceiverSiteCount = pvHavingDifferentDeliveriesWithSameReceiverSite[d.planningVehicleId]?.length ?? 0
            const pvHavingDifferentDeliveriesCount = pvHavingDifferentDeliveries[d.planningVehicleId]?.length ?? 0;
            d.hasDifferentDeliveriesWithDifferentReceiverSites = pvHavingDifferentDeliveriesCount > pvHavingDifferentDeliveriesWithSameReceiverSiteCount;
        });

        this.setTransportSellFlowsVehiclesVisibilities(transportSellFlowsVehiclesList, state);

        preInvoiceCosts = this.getPreInvoiceCosts(transportSellFlowsVehiclesList);

        return {
            transportSales: transportSellFlowsVehiclesList,
            preInvoiceCosts: preInvoiceCosts
        };
    }

    sumTransportSellPrice = (items: Array<TransportSaleLightModelExtended>, prop: string) => {
        return items.filter(x => x.isVisible).reduce(function (a, b) {
            return a + b[prop];
        }, 0);
    }

    translateTransportSellLightModel = (transportSell: TransportSaleLightModelExtended, groupKey: string): void => {
        transportSell.priorityBase = transportSell.flowPriority;
        transportSell.transportSellPriceKindBase = transportSell.transportSellPriceKind;
        transportSell.flowPriority = this.getLabelFromDict('TransportPriority', transportSell.flowPriority);
        transportSell.transportSellPriceKind = this.getLabelFromDict('TransportSellPriceKind', transportSell.transportSellPriceKind);
        transportSell.groupKey = groupKey;
    }

    setTransportSellFlowsVehiclesVisibilities = (transportSellFlowsVehiclesList: TransportSaleLightModelExtended[], state: TransportSalesState): void => {
        let transportFlowObjs = transportSellFlowsVehiclesList.filter(obj => obj.entity === "transportFlow");
        transportFlowObjs.forEach(transportFlowObj => {
            let isNightWork = transportFlowObj.isNightWork;
            let transportRequestId = transportFlowObj.transportRequestId;
            transportSellFlowsVehiclesList.forEach(obj => {
                if (obj.transportRequestId === transportRequestId) {
                    if ((state.isPerDayFilterActive && state.isPerNightFilterActive && isNightWork) ||
                        (state.isPerDayFilterActive && state.isPerNightFilterActive && !isNightWork) ||
                        (state.isPerDayFilterActive && !state.isPerNightFilterActive && !isNightWork) ||
                        (!state.isPerDayFilterActive && state.isPerNightFilterActive && isNightWork)) {
                        obj.isVisible = true;
                    }
                    else if ((state.isPerDayFilterActive && !state.isPerNightFilterActive && isNightWork) ||
                        (!state.isPerDayFilterActive && state.isPerNightFilterActive && !isNightWork) ||
                        (!state.isPerDayFilterActive && !state.isPerNightFilterActive && isNightWork) ||
                        (!state.isPerDayFilterActive && !state.isPerNightFilterActive && !isNightWork)) {
                        obj.isVisible = false;
                    }
                }
            });
        });
    }

    getLabelFromDict = (objectName: string, fieldName: string): string => {
        const res = propertyLabels.get(`${objectName}.${fieldName}`);
        return res !== undefined ? res : '';
    }

    handleChangeDateRange = (event: DateRange): void => {
        if (event.end != null && event.start != null) {
            const fromTime = event.start.getDayStart();
            const toTime = event.end.getDayEnd();
            const { date } = this.state;

            if (!fromTime.equals(date.start) || !toTime.equals(date.end)) {
                this.setState({
                    date: { start: fromTime, end: toTime }
                });
                this.SearchPreInvoices({ start: fromTime, end: toTime }, this.state.searchText, this.state.filtersSelected, this.state.toPreInvoiceOnly, this.state.toIntegrateOnly, this.state.hasSendingError, this.state.selectedLogisticsUnitIds);
                this.GetVehiclesCostsWithMargin({ start: fromTime, end: toTime }, this.state.selectedLogisticsUnitIds);
            }
        }
    }

    rowRender = (trElement: React.ReactElement<HTMLTableRowElement>, rowProps: GridRowProps): React.ReactElement<HTMLTableRowElement> => {
        const entity = rowProps.dataItem.entity;
        let className = trElement.props.className;


        if (entity == TransportSaleEntityLightModel.planningVehicle) {
            className = className.replace('k-selected', '');
            className = className + ' vehicle';
        }
        else if (entity == TransportSaleEntityLightModel.transportFlow) {
            if (rowProps.dataItem.regularizationNumber)
                className = className + ' header-flow-regularization';
            else
                className = className + ' header-flow';
        }
        else {
            className = className + ' header-request';
        }

        const trProps = {
            ...trElement.props,
            onMouseDown: () => {
                this.preventExit = true;
                clearTimeout(this.preventExitTimeout);
                this.preventExitTimeout = setTimeout(() => { this.preventExit = undefined; });
            },
            onBlur: () => {
                clearTimeout(this.blurTimeout);
                if (!this.preventExit) {
                    this.blurTimeout = setTimeout(() => { this.exitEdit(); });
                }
            },
            onFocus: () => { clearTimeout(this.blurTimeout); },
            onContextMenu: (e: MouseEvent) => {
                e.preventDefault();
                this.handleContextMenu(e, rowProps.dataItem);
            }
        };
        return React.cloneElement(trElement, { ...trProps, className: className }, trElement.props.children as any);
    }

    handleContextMenu = (e: MouseEvent, dataItem: TransportSaleLightModelExtended) => {
        if (dataItem.entity === TransportSaleEntityLightModel.transportFlow) {
            this.setState({
                contextMenuOffset: {
                    left: e.clientX,
                    top: e.clientY
                },
                contextMenuOpen: true,
                contextMenuRow: dataItem
            });
        }
        else {
            this.setState({
                contextMenuOffset: {
                    left: -1,
                    top: -1
                },
                contextMenuOpen: false,
                contextMenuRow: null
            });
        }
    }

    cellRender = (tdElement: React.ReactElement<HTMLTableCellElement>, cellProps: GridCellProps): React.ReactElement<HTMLTableCellElement> => {
        const dataItem: TransportSaleLightModelExtended = cellProps.dataItem;
        const field = cellProps.field;
        const additionalProps = (dataItem.inEdit && (field === dataItem.inEdit)) ?
            {
                //https://www.telerik.com/forums/issues-with-in-cell-editing-in-the-grid
                ref: (td: HTMLTableCellElement) => {
                    const input = td && td.querySelector('input');
                    if (!input || (input === document.activeElement)) { return; }

                    input.select();
                }
            } : {
                onClick: () => { this.enterEdit(dataItem, field); }
            };
        return React.cloneElement(tdElement, { ...tdElement.props, ...additionalProps }, tdElement.props.children as any);
    }

    enterEdit = (dataItem: TransportSaleLightModelExtended, field: string): void => {
        if (dataItem.inEdit && field === this.state.editField) {
            return;
        }

        this.exitEdit();
        dataItem.inEdit = field;
        this.setState({
            editField: field
        });
    }

    exitEdit = (): void => {
        let wasEditing = false;
        let propertyName = "";
        let propertyValue: any;
        let planningIdToUpdate = -1;
        let transportFlowIdToUpdate = null;
        let preInvoiceIdToUpdate = null;
        let planningVehicleIdToUpdate = null;
        let versionOfAggregateToUpdate = 0;

        const { transportSellFlowsVehiclesListAll } = this.state;

        this.setState({
            editField: null
        });

        const elem = transportSellFlowsVehiclesListAll.find(e => e.inEdit != null);
        if (elem) {
            propertyName = elem.inEdit;
            propertyValue = elem[propertyName];
            planningVehicleIdToUpdate = elem.planningVehicleId;
            planningIdToUpdate = elem.planningId;
            transportFlowIdToUpdate = elem.transportFlowId;
            preInvoiceIdToUpdate = elem.preInvoiceId;
            versionOfAggregateToUpdate = elem.versionOfAggregate;
            elem.inEdit = null;
            if (propertyName == "preInvoiceComments" && elem.preInvoiceRemarksBase != propertyValue) {
                wasEditing = true;
                elem.preInvoiceRemarksBase = propertyValue;
            }

            if (propertyName == "invoicePrice" && elem.preInvoiceBillingPriceBase != propertyValue) {
                wasEditing = true;
                elem.preInvoiceBillingPriceBase = propertyValue;
            }

            this.setState({
                transportSellFlowsVehiclesListAll: transportSellFlowsVehiclesListAll
            });
        }

        if (!wasEditing)
            return;

        if (elem.entity != TransportSaleEntityLightModel.planningVehicle || (propertyName !== "preInvoiceComments" && propertyName !== "invoicePrice"))
            return;

        switch (propertyName) {
            case "preInvoiceComments":
                {
                    const preInvoiceDetailChange: PreInvoiceDetailChangeRemarksRequestArgs = {
                        preInvoiceId: preInvoiceIdToUpdate,
                        versionOfAggregate: versionOfAggregateToUpdate,
                        planningId: planningIdToUpdate,
                        transportFlowId: transportFlowIdToUpdate,
                        planningVehicleId: planningVehicleIdToUpdate,
                        preInvoiceDetailRemarks: propertyValue
                    }

                    TransportSalesApiClient.UpdatePreInvoiceDetailRemarks(
                        preInvoiceDetailChange.preInvoiceId,
                        preInvoiceDetailChange.planningId,
                        preInvoiceDetailChange.transportFlowId,
                        preInvoiceDetailChange.versionOfAggregate,
                        preInvoiceDetailChange.planningVehicleId,
                        preInvoiceDetailChange.preInvoiceDetailRemarks)
                        .then(res => {
                            const errors = BusinessErrors.Get(res.data);

                            if (errors.length > 0) {
                                ToastService.showErrorToast("Une ou plusieurs erreur(s) rencontrée(s) lors de la mise à jour du commentaire de facturation:", errors);
                                this.SearchPreInvoices(this.state.date, this.state.searchText, this.state.filtersSelected, this.state.toPreInvoiceOnly, this.state.toIntegrateOnly, this.state.hasSendingError, this.state.selectedLogisticsUnitIds);
                            }
                            else {
                                this.incrementVersionOfAggregate(transportFlowIdToUpdate, versionOfAggregateToUpdate);
                            }
                        });
                    break;
                }

            case "invoicePrice":
                {
                    const preInvoiceDetailChange: PreInvoiceDetailChangeInvoicePriceRequestArgs = {
                        preInvoiceId: preInvoiceIdToUpdate,
                        versionOfAggregate: versionOfAggregateToUpdate,
                        planningId: planningIdToUpdate,
                        transportFlowId: transportFlowIdToUpdate,
                        planningVehicleId: planningVehicleIdToUpdate,
                        preInvoiceDetailInvoicePrice: propertyValue ? propertyValue : 0
                    };

                    TransportSalesApiClient.UpdatePreInvoiceDetailInvoicePrice(
                        preInvoiceDetailChange.preInvoiceId,
                        preInvoiceDetailChange.planningId,
                        preInvoiceDetailChange.transportFlowId,
                        preInvoiceDetailChange.versionOfAggregate,
                        preInvoiceDetailChange.planningVehicleId,
                        preInvoiceDetailChange.preInvoiceDetailInvoicePrice)
                        .then(res => {
                            const errors = BusinessErrors.Get(res.data);

                            if (errors.length > 0) {
                                ToastService.showErrorToast("Une ou plusieurs erreur(s) rencontré(e) lors de la mise à jour du prix:", errors);
                                this.SearchPreInvoices(this.state.date, this.state.searchText, this.state.filtersSelected, this.state.toPreInvoiceOnly, this.state.toIntegrateOnly, this.state.hasSendingError, this.state.selectedLogisticsUnitIds);
                            }
                            else {
                                this.GetVehiclesCostsWithMargin(this.state.date, this.state.selectedLogisticsUnitIds, true);
                                this.incrementVersionOfAggregate(transportFlowIdToUpdate, versionOfAggregateToUpdate);
                            }
                        });
                    break;
                }
            default:
                break;
        }
    }

    incrementVersionOfAggregate = (transportFlowIdToUpdate: string, versionOfAggregateToUpdate: number) => {
        const transportSellFlowsVehiclesListAll = [...this.state.transportSellFlowsVehiclesListAll];
        transportSellFlowsVehiclesListAll.filter(x => x.transportFlowId == transportFlowIdToUpdate).forEach(t => {
            t.versionOfAggregate = versionOfAggregateToUpdate + 1;
        });
        this.setState({
            transportSellFlowsVehiclesListAll: transportSellFlowsVehiclesListAll
        });
    }

    handleLogisticsUnitsChange = (selectedLogisticsUnitIds: string[]): void => {
        if (!isEqual(selectedLogisticsUnitIds, this.state.selectedLogisticsUnitIds)) {
            this.setState({
                selectedLogisticsUnitIds: selectedLogisticsUnitIds
            }, () => {
                this.SearchPreInvoices(this.state.date, this.state.searchText, this.state.filtersSelected, this.state.toPreInvoiceOnly, this.state.toIntegrateOnly, this.state.hasSendingError, selectedLogisticsUnitIds);
                this.GetVehiclesCostsWithMargin(this.state.date, selectedLogisticsUnitIds);
            });

            if (selectedLogisticsUnitIds.length === 0) {
                this.setState({
                    selectedLogisticsUnitIds: [],
                    transportSellFlowsVehiclesListAll: []
                });
            }
        }
    }

    updateTransportSellChoices = (checked: boolean, elemId: string, entity: string, planningId?: number): void => {
        const { transportSellFlowsVehiclesListAll } = this.state;
        const transportSellFlowsVehiclesList = [...transportSellFlowsVehiclesListAll];

        const elem = entity == TransportSaleEntityLightModel.transportRequest ?
            transportSellFlowsVehiclesList.find(p => (p.groupKey == elemId)
                && p.entity == TransportSaleEntityLightModel.transportRequest)
            : transportSellFlowsVehiclesList.find(p => ((p.preInvoiceId && p.preInvoiceId == elemId) || (!p.preInvoiceId && p.transportFlowId === elemId && p.planningId === planningId))
                && p.entity == TransportSaleEntityLightModel.transportFlow && p.planningId == planningId);

        elem.checked = checked;

        if (elem.entity == TransportSaleEntityLightModel.transportRequest) {
            transportSellFlowsVehiclesList.filter(tf => tf.groupKey == elemId && tf.entity == TransportSaleEntityLightModel.transportFlow).forEach(e => {
                e.checked = checked;
            });
        }
        else {
            const transportRequest = transportSellFlowsVehiclesList.find(p => p.groupKey == elem.groupKey && p.entity == TransportSaleEntityLightModel.transportRequest);
            if (transportRequest) {
                if (!checked) {
                    transportRequest.checked = false;
                }
                else {
                    if (!transportSellFlowsVehiclesList.find(t => t.groupKey == elem.groupKey && t.entity == TransportSaleEntityLightModel.transportFlow && !t.checked)) {
                        transportRequest.checked = true;
                    }
                    else {
                        transportRequest.checked = false;
                    }
                }
            }
        }

        this.setState({
            transportSellFlowsVehiclesListAll: transportSellFlowsVehiclesList
        });
    }

    updateAllTransportSells = (checked: boolean): void => {
        const transportSellFlowsVehiclesList = [...this.state.transportSellFlowsVehiclesListAll];

        transportSellFlowsVehiclesList.forEach(t => {
            t.checked = checked;
        });

        this.setState({
            transportSellFlowsVehiclesListAll: transportSellFlowsVehiclesList
        });
    }

    openMarginVehicles = (): void => {
        this.setState({
            openMargin: true
        });
    }

    handleMarginVehiclesOpen = (open: boolean): void => {
        this.setState({
            openMargin: open
        });
    }

    handleChangeFilterSelected = (filtersSelected: Array<TextSearchField>) => {
        //vérifier s'il y'a eu du changement sur la liste des filtres
        const difference: Array<TextSearchField> = this.state.filtersSelected
            .filter(x => !filtersSelected.includes(x))
            .concat(filtersSelected.filter(x => !this.state.filtersSelected.includes(x)));

        this.setState({
            lastTimeStampFilteredSelected: difference.length > 0 ? Date.now() : this.state.lastTimeStampFilteredSelected,
            filtersSelected: filtersSelected
        });
    }

    handleChangeFilterSelect = (option: OptionSelectFilter): void => {

        this.setState({
            toPreInvoiceOnly: option == OptionSelectFilter.toPreInvoiceOnly,
            toIntegrateOnly: option == OptionSelectFilter.toIntegrateOnly,
            hasSendingError: option == OptionSelectFilter.hasSendingError
        }, () => {
            this.SearchPreInvoices(this.state.date, this.state.searchText, this.state.filtersSelected, this.state.toPreInvoiceOnly, this.state.toIntegrateOnly, this.state.hasSendingError, this.state.selectedLogisticsUnitIds);
        });
    }

    handleClickCloseDialog = (reason?: string) => {
        if (reason !== "backdropClick") {
            this.setState({
                simpleDialog: {
                    isDialogOpened: false,
                    dialogTitle: '',
                    popupContentComponent: <></>
                },
                contextMenuRow: null,
            });
        }
    }

    handleClickOpenDialogRegularization = (preInvoiceId: string, businessId: string) => {
        this.setState({
            contextMenuOpen: false,
            contextMenuOffset: {
                left: -1,
                top: -1
            },
            simpleDialog: {
                isDialogOpened: true,
                dialogTitle: `Régularisation du flux ${businessId}: `,
                popupContentComponent: <RegularizationPreInvoiceTemplateComponent preInvoiceId={preInvoiceId} handleRefreshPreInvoiceRegularizations={this.handleRefreshPreInvoiceRegularizations} />
            },
            preInvoiceRegularizations: {
                lastAppliedRegularizationNumber: null,
                details: []
            }
        });
    }

    handleRefreshPreInvoiceRegularizations = (preInvoiceRegularizations: NewPreInvoiceRegularizationInitModelExtended): void => {
        let total = 0;
        preInvoiceRegularizations.details.forEach(p => {
            total += p.regularizationPrice ?? 0;
        });

        this.setState({
            simpleDialog: {
                ...this.state.simpleDialog,
                dialogTitle: `Régularisation du flux ${this.state.contextMenuRow.businessId}: ${total.toCurrencyString()}`
            },
            preInvoiceRegularizations: preInvoiceRegularizations
        });
    }

    CreateRegularizationPreInvoice = (): void => {
        const { preInvoiceRegularizations, contextMenuRow } = this.state;

        const requestArgs: CreateRegularizationPreInvoiceRequestArg = {
            originalPreInvoiceId: contextMenuRow.preInvoiceId,
            lastAppliedRegularizationNumber: preInvoiceRegularizations.lastAppliedRegularizationNumber,
            details: preInvoiceRegularizations.details.filter(d => d.regularizationPrice).map(d => {
                return {
                    planningVehicleId: d.planningVehicleId,
                    planningVehicleBuyerLogisticsUnitId: d.planningVehicleBuyerLogisticsUnitId,
                    planningVehicleBuyerLogisticsUnitLabel: d.planningVehicleBuyerLogisticsUnitLabel,
                    planningVehicleNumber: d.planningVehicleNumber,
                    vehicleId: d.vehicleId,
                    vehicleLicencePlate: d.vehicleLicencePlate,
                    vehicleEquipmentId: d.vehicleEquipmentId,
                    transporterId: d.transporterId,
                    transporterName: d.transporterName,
                    vehicleTypeId: d.vehicleTypeId,
                    vehicleTypeLabel: d.vehicleTypeLabel,
                    invoicePrice: d.regularizationPrice,
                    planningVehicleComments: d.planningVehicleComments,
                    preInvoiceComments: d.regularizationComments
                }
            })
        };

        TransportSalesApiClient.CreateRegularizationPreInvoice(requestArgs)
            .then(res => {
                const errors = BusinessErrors.Get(res.data);

                if (errors.length > 0) {
                    ToastService.showErrorToast(" Une ou plusieurs erreur(s) rencontrée(s) lors de la création de la régularisation de BL:", errors);
                }

                const selectedIds = [requestArgs.originalPreInvoiceId];
                this.SearchPreInvoices(this.state.date, this.state.searchText, this.state.filtersSelected, this.state.toPreInvoiceOnly, this.state.toIntegrateOnly, this.state.hasSendingError, this.state.selectedLogisticsUnitIds, null, selectedIds);
                this.GetVehiclesCostsWithMargin({ start: this.state.date.start, end: this.state.date.end }, this.state.selectedLogisticsUnitIds);
            })
            .finally(() => {
                this.handleClickCloseDialog();
            });
    }

    removePreInvoice = (): void => {
        const { contextMenuRow } = this.state;

        if (contextMenuRow) {
            const requestArgs: RemovePreInvoiceRequestArgs = {
                preInvoiceId: contextMenuRow.preInvoiceId,
                planningId: contextMenuRow.planningId,
                transportFlowId: contextMenuRow.transportFlowId,
                versionOfAggregate: contextMenuRow.versionOfAggregate
            };
            TransportSalesApiClient.RemovePreInvoice(requestArgs)
                .then(res => {
                    const errors = BusinessErrors.Get(res.data);

                    if (errors.length > 0) {
                        ToastService.showErrorToast("Une ou plusieurs erreur(s) rencontrée(s) lors de la suppréssion du BL:", errors);
                        return;
                    }

                })
                .finally(() => {
                    this.SearchPreInvoices(this.state.date, this.state.searchText, this.state.filtersSelected, this.state.toPreInvoiceOnly, this.state.toIntegrateOnly, this.state.hasSendingError, this.state.selectedLogisticsUnitIds);
                    this.GetVehiclesCostsWithMargin({ start: this.state.date.start, end: this.state.date.end }, this.state.selectedLogisticsUnitIds);
                });
        }
        this.setState({
            contextMenuRow: null,
            contextMenuOpen: false,
            contextMenuOffset: {
                left: -1,
                top: -1
            }
        });
    }

    copyFlowPriceIntoInvoicePrice = (): void => {
        const { contextMenuRow } = this.state;

        if (contextMenuRow) {
            const requestArgs: CopyFlowPriceIntoInvoicePriceRequestArgs = {
                preInvoiceId: contextMenuRow.preInvoiceId,
                planningId: contextMenuRow.planningId,
                transportFlowId: contextMenuRow.transportFlowId,
                versionOfAggregate: contextMenuRow.versionOfAggregate
            };
            TransportSalesApiClient.CopyFlowPriceIntoInvoicePrice(requestArgs)
                .then(res => {
                    const errors = BusinessErrors.Get(res.data);

                    if (errors.length > 0) {
                        ToastService.showErrorToast("Une ou plusieurs erreur(s) rencontrée(s) lors de la copie du prix de vente du flux dans le prix de vente à facture du BL:", errors);
                        return;
                    }

                })
                .finally(() => {
                    const selectedIds = [contextMenuRow.preInvoiceId];
                    this.SearchPreInvoices(this.state.date, this.state.searchText, this.state.filtersSelected, this.state.toPreInvoiceOnly, this.state.toIntegrateOnly, this.state.hasSendingError, this.state.selectedLogisticsUnitIds, null, selectedIds);
                    this.GetVehiclesCostsWithMargin({ start: this.state.date.start, end: this.state.date.end }, this.state.selectedLogisticsUnitIds);
                });
        }
        this.setState({
            contextMenuRow: null,
            contextMenuOpen: false,
            contextMenuOffset: {
                left: -1,
                top: -1
            }
        });
    }

    handleCloseTransportFlowForm = (): void => {
        this.setState({
            isTransportFlowFormViewOpened: false
        });
    }

    handleCloseTransportFlowFormAfterValidation = (hasBusinessErrors: boolean, hasBusinessWarnings: boolean, warnings: string[], businessId: string, mode: string): void => {
        const state = { ...this.state };

        if (!hasBusinessErrors) {
            this.HandleConfirmGeneratePreInvoicesFromTransportFlows(true);
            ToastService.showSuccessToast(`La modification du Flux ${businessId} s'est déroulée avec succès`);
            state.isTransportFlowFormViewOpened = false;
        } else {
            state.isTransportFlowFormViewOpened = false;

            ToastService.showErrorToast(`La modification du Flux ${businessId} a échoué. Veuillez contacter votre administrateur`);
        }

        this.setState(state);
    }

    handleEditFlow = () => {
        const { contextMenuRow } = this.state;

        if (contextMenuRow) {
            if (contextMenuRow.lastWriteDate) {
                this.setState({
                    contextMenuOpen: false,
                    contextMenuOffset: {
                        left: -1,
                        top: -1
                    },
                    showNewDataFieldsWillLostAfterEditFlow: true
                });

                return;
            }

            this.editFlow();
        }

        this.setState({
            contextMenuOpen: false,
            contextMenuOffset: {
                left: -1,
                top: -1
            }
        });
    }

    editFlow = (): void => {
        this.setState({
            isTransportFlowFormViewOpened: true,
            showNewDataFieldsWillLostAfterEditFlow: false,
            contextMenuOpen: false,
            contextMenuOffset: {
                left: -1,
                top: -1
            }
        });
    }

    handlePerDayFilterChanged = (state: TransportSalesState): void => {
        state.isPerDayFilterActive = !state.isPerDayFilterActive;

        this.handleFilterSellFlowsVehiclesPerDayNight(state);
    }

    handlePerNightFilterChanged = (state: TransportSalesState): void => {
        state.isPerNightFilterActive = !state.isPerNightFilterActive;

        this.handleFilterSellFlowsVehiclesPerDayNight(state);
    }

    handleSelectAllVehiclesFilterChanged = (state: TransportSalesState): void => {
        state.isPerDayFilterActive = true;
        state.isPerNightFilterActive = true;

        this.handleFilterSellFlowsVehiclesPerDayNight(state);
    }

    handleFilterSellFlowsVehiclesPerDayNight = (state: TransportSalesState): void => {
        let transportSellFlowsVehiclesList = cloneDeep(state.transportSellFlowsVehiclesListAll);
        this.setTransportSellFlowsVehiclesVisibilities(transportSellFlowsVehiclesList, state);

        state.transportSellFlowsVehiclesListAll = transportSellFlowsVehiclesList;
        state.preInvoiceCosts = this.getPreInvoiceCosts(transportSellFlowsVehiclesList);

        this.setState(state);
    }

    getPreInvoiceCosts = (transportSellFlowsVehiclesList: TransportSaleLightModelExtended[]): { costsToIntegrate: number, integratedCosts: number } => {
        let totalCostsToIntegrate = 0;
        let totalIntegratedCosts = 0;

        const allFlows = transportSellFlowsVehiclesList.filter(e => e.entity === TransportSaleEntityLightModel.transportFlow && e.isVisible === true);
        allFlows.forEach(f => {
            if (f.hasAlreadyPreInvoices) {
                if (f.sentStatus === PreInvoiceSentStatusLightModel.successfully) {
                    totalIntegratedCosts += f.invoicePrice ?? 0;
                }
                else {
                    totalCostsToIntegrate += f.invoicePrice ?? 0;
                }
            }
        });

        return {
            costsToIntegrate: totalCostsToIntegrate,
            integratedCosts: totalIntegratedCosts
        };
    }

    render() {
        const { transportSellFlowsVehiclesListAll, loading, groupLabelFilter
            , generatedPreInvoicesLoading, generatedPreInvoices, nbElemsToGenerated, generatedPreInvoicesLogisticsUnitLabel, nbLogisticsUnit, nbLogisticsUnitWithGeneratedPreInvoices, sentPreInvoicesLoading, sentPreInvoicesLogisticsUnitLabel, nbLogisticsUnitWithSendPreInvoices, sentPreInvoices, nbElemsToSent
            , showNewDataFieldsWillLost, showNewDataFieldsWillLostAfterEditFlow, showCanceledSalesWithPriceConfirmation, openMargin, transportSaleCostsList, gridPageSize, gridPageSizeDrawerMargin, gridHeight, gridHeightDrawerMargin
            , loadingTransportSaleCostsMargin, totalCosts, totalInvoicePrices, totalMargins, contextMenuOffset, contextMenuOpen, contextMenuRow, simpleDialog, preInvoiceRegularizations, preInvoiceCosts, isTransportFlowFormViewOpened } = this.state;
        const inputSearchVehiclesValue = this.inputSearchTransportSellRef.current === null || this.inputSearchTransportSellRef.current === undefined ? '' : this.inputSearchTransportSellRef.current.value;
        const transportSellFlowsVehiclesList = transportSellFlowsVehiclesListAll;
        const hasAnyVehicles = (transportSellFlowsVehiclesList.find(t => t.entity == TransportSaleEntityLightModel.planningVehicle)) ? true : false;
        const hasAnyRegularization = preInvoiceRegularizations.details.some(d => d.regularizationPrice);

        const contents = loading
            ? <div className='sweet-loading spinnerClass'>
                <ScaleLoader
                    width={5}
                    height={20}
                    radius={50}
                    color={'#000000'}
                    loading={loading}
                />
            </div>
            :
            <Box display="flex" height="100%">
                <TransportSalesComponent
                    planningVehiclesList={transportSellFlowsVehiclesList}
                    path={this.path}
                    role={this.props.role}
                    enableEdit={this.enableEdit}
                    gridHeight={gridHeight} gridPageSize={gridPageSize}
                    selectedAll={!transportSellFlowsVehiclesList.find(t => (t.entity == TransportSaleEntityLightModel.transportFlow || (t.businessId && t.entity == TransportSaleEntityLightModel.transportRequest)) && !t.checked)}
                    groupLabelFilter={groupLabelFilter}
                    rowRender={this.rowRender}
                    cellRender={this.cellRender}
                    updateTransportSellChoices={this.updateTransportSellChoices}
                    updateAllTransportSells={this.updateAllTransportSells}
                    refreshComputedCells={this.refreshComputedCells}
                    handleSearchPreInvoices={this.handleSearchPreInvoices}
                />
            </Box>

        const communContent = (
            <Box display="flex" flexWrap="nowrap" className="component-content" flexDirection="column">
                <ContentHeaderComponent
                    logisticsUnits={this.props.logisticsUnits}
                    inputSearchTransportSellValue={inputSearchVehiclesValue}
                    inputSearchTransportSellRef={this.inputSearchTransportSellRef}
                    path={this.path}
                    date={this.state.date}
                    groupLabelFilter={groupLabelFilter}
                    hasAnyFlowToPreInvoicesSelected={some(transportSellFlowsVehiclesList, e => e.checked == true && e.entity == TransportSaleEntityLightModel.transportFlow && e.sentStatus !== PreInvoiceSentStatusLightModel.successfully && !e.regularizationNumber)}
                    hasAnyPreInvoiceSelected={some(transportSellFlowsVehiclesList, e => e.checked == true && e.preInvoiceId && e.entity == TransportSaleEntityLightModel.transportFlow && e.sentStatus !== PreInvoiceSentStatusLightModel.successfully)}
                    hasAnyVehicles={hasAnyVehicles}
                    nbTodoPreInvoices={countBy(transportSellFlowsVehiclesList, e => e.checked == true && e.entity == TransportSaleEntityLightModel.transportFlow && e.sentStatus !== PreInvoiceSentStatusLightModel.successfully && !e.hasAlreadyPreInvoices && !e.regularizationNumber).true}
                    openMargin={openMargin}
                    filterOptions={this.filterOptions}
                    filterSelectOptions={this.filterSelectOptions}
                    preInvoiceCosts={preInvoiceCosts}
                    ModuleKey={ModuleKey}
                    LogisticsUnitsSelectedOptionsStorageKey={LogisticsUnitsSelectedOptionsStorageKey}
                    isPerDayFilterActive={this.state.isPerDayFilterActive}
                    isPerNightFilterActive={this.state.isPerNightFilterActive}
                    handleChangeFilterSelect={this.handleChangeFilterSelect}
                    handleChangeDateRange={this.handleChangeDateRange}
                    handleTransportSellKeyPress={this.handleTransportSellKeyPress}
                    clearSearchText={this.clearSearchText}
                    handleLogisticsUnitsChange={this.handleLogisticsUnitsChange}
                    generatePreInvoicesFromTransportFlows={this.GeneratePreInvoicesFromTransportFlows}
                    sendIntegrationPreInvoices={this.sendIntegrationPreInvoices}
                    handleMarginVehiclesOpen={this.handleMarginVehiclesOpen}
                    onChangeTextSearchFields={this.handleChangeFilterSelected}
                    handleChangeGroup={this.handleChangeGroup}
                    handleSelectAllVehiclesFilterChanged={() => this.handleSelectAllVehiclesFilterChanged(this.state)}
                    handlePerDayFilterChanged={() => this.handlePerDayFilterChanged(this.state)}
                    handlePerNightFilterChanged={() => this.handlePerNightFilterChanged(this.state)}
                />
                <Box className="alerte-message">
                    Pour des raisons de performance il n'est pas possible d'afficher toutes les données des camions en temps réel. Utilisez le bouton "Synchroniser" pour mettre à jour les Flux sélectionnés
                </Box>
                {contents}
            </Box>);

        const ret =
            <>
                <Box display="flex" flexWrap="nowrap" flexDirection="column" minHeight="340px" height="100%" className={`${this.state.openMargin ? 'component-content-shiftRight' : ''}`}>
                    {communContent}
                </Box>
                <MarginDrawerComponent handleMarginDrawerClose={this.handleMarginVehiclesOpen} isMarginDrawerOpened={this.state.openMargin} vehiclesList={transportSaleCostsList} loading={loadingTransportSaleCostsMargin}
                    gridHeight={gridHeightDrawerMargin} gridPageSize={gridPageSizeDrawerMargin} totalCosts={totalCosts} totalInvoicePrices={totalInvoicePrices} totalMargins={totalMargins}
                />
                <Modal show={showNewDataFieldsWillLost} onHide={this.HandleHideGeneratePreInvoicesFromTransportFlows} className='mt-5'>
                    <Modal.Header closeButton>
                        <Modal.Title>ATTENTION</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        La synchronisation entrainera la perte des données saisies dans l'écran Ventes pour le BL concerné.<br />
                        Confirmez-vous l'action ?
                    </Modal.Body>
                    <Modal.Footer>
                        <Button className="secondary" onClick={this.HandleHideGeneratePreInvoicesFromTransportFlows}>
                            ANNULER
                        </Button>
                        <Button className="primary" onClick={() => this.HandleConfirmGeneratePreInvoicesFromTransportFlows(false)}>
                            CONFIRMER
                        </Button>
                    </Modal.Footer>
                </Modal>

                <Modal show={showCanceledSalesWithPriceConfirmation}
                    onHide={this.handleHideSendIntegrationPreInvoices} className='mt-5'>
                    <Modal.Header closeButton>
                        <Modal.Title>ATTENTION</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        Au moins un Flux comporte un camion avec des ventes annulées dont le prix de vente à facturer est non nul.<br />
                        Confirmez-vous l'action ?
                    </Modal.Body>
                    <Modal.Footer>
                        <Button className="secondary" onClick={this.handleHideSendIntegrationPreInvoices}>
                            ANNULER
                        </Button>
                        <Button className="primary" onClick={() => this.handleSendIntegrationPreInvoices(true)}>
                            CONFIRMER
                        </Button>
                    </Modal.Footer>
                </Modal>


                <Modal show={showNewDataFieldsWillLostAfterEditFlow} onHide={this.HandleHideGeneratePreInvoicesFromTransportFlowsAfterEditFlow} className='mt-5'>
                    <Modal.Header closeButton>
                        <Modal.Title>ATTENTION</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        La synchronisation entrainera la perte des données saisies dans l'écran Ventes pour le BL concerné.<br />
                        Confirmez-vous l'action ?
                    </Modal.Body>
                    <Modal.Footer>
                        <Button className="secondary" onClick={this.HandleHideGeneratePreInvoicesFromTransportFlowsAfterEditFlow}>
                            ANNULER
                        </Button>
                        <Button className="primary" onClick={() => this.editFlow()}>
                            CONFIRMER
                        </Button>
                    </Modal.Footer>
                </Modal>
                <Modal show={generatedPreInvoicesLoading} className='mt-5'
                    backdrop="static"
                    keyboard={false}>
                    <Modal.Body>
                        <Box display="flex" alignItems='center' flexDirection="column">
                            <Box p={1}>
                                <CircularProgress />
                            </Box>
                            <div>
                                Synchronisation en cours:
                            </div>
                            <div>
                                Zone logistique ({`${nbLogisticsUnitWithGeneratedPreInvoices}/${nbLogisticsUnit}`}) : <b>{`${generatedPreInvoicesLogisticsUnitLabel}`}</b>
                            </div>
                            <div>
                                <b>{`${generatedPreInvoices}/${nbElemsToGenerated}`}</b>&nbsp;Flux synchronisés
                            </div>
                        </Box>
                    </Modal.Body>
                </Modal>
                <Modal show={sentPreInvoicesLoading} className='mt-5'
                    backdrop="static"
                    keyboard={false}>
                    <Modal.Body>
                        <Box display="flex" alignItems='center' flexDirection="column">
                            <Box p={1}>
                                <CircularProgress />
                            </Box>
                            <div>
                                Envoi des BL de vente de transport en cours:
                            </div>
                            <div>
                                Zone logistique ({`${nbLogisticsUnitWithSendPreInvoices}/${nbLogisticsUnit}`}) : <b>{`${sentPreInvoicesLogisticsUnitLabel}`}</b>
                            </div>
                            <div>
                                <b>{`${sentPreInvoices}/${nbElemsToSent}`}</b>&nbsp;Flux intégrés
                            </div>
                        </Box>
                    </Modal.Body>
                </Modal>
                <Popup
                    offset={contextMenuOffset}
                    show={contextMenuOpen}
                    popupClass={"popup-content"}
                >
                    <ClickAwayListener onClickAway={(e) => this.setState({ contextMenuOpen: false, contextMenuRow: null, contextMenuOffset: { left: -1, top: -1 } })}>
                        <Menu vertical={true} >
                            {!contextMenuRow?.regularizationNumber &&
                                (<MenuItem render={(props: MenuItemProps) => {
                                    const hasAnotherFlowsSelected = transportSellFlowsVehiclesListAll.some(x => x.entity === TransportSaleEntityLightModel.transportFlow && x.checked === true
                                        && ((contextMenuRow?.preInvoiceId && x.preInvoiceId !== contextMenuRow?.preInvoiceId) || (!contextMenuRow?.preInvoiceId && `${x.transportFlowId}-${x.planningId}` !== `${contextMenuRow?.transportFlowId}-${contextMenuRow?.planningId}`)));
                                    const title = hasAnotherFlowsSelected
                                        ? "Impossible de faire cette action: Une ou plusieurs ligne(s) sélectionnée(s)"
                                        : contextMenuRow?.sentStatus === PreInvoiceSentStatusLightModel.successfully
                                            ? "Impossible de faire cette action: Ce BL de vente a déjà été intégré"
                                            : "";

                                    return (<div onClick={event => event.stopPropagation()} className="menu-item-div">
                                        {(hasAnotherFlowsSelected || contextMenuRow?.sentStatus === PreInvoiceSentStatusLightModel.successfully) ?
                                            <Tooltip title={title} placement="right">
                                                <Box display="flex" flexDirection="row" width="100%" className="menu-item-disabled">
                                                    <Box pr={'13px'} className="menu-item-icon">
                                                        <FontAwesomeIcon size="lg" icon={faCamera} />
                                                    </Box>
                                                    <span>Synchroniser avec le planning</span>
                                                </Box>
                                            </Tooltip>
                                            :
                                            <div onClick={(e) => this.generatePreInvoiceFromTransportFlowByContextMenu(false)} >
                                                <Box display="flex" flexDirection="row" width="100%">
                                                    <Box pr={'13px'} className="menu-item-icon">
                                                        <FontAwesomeIcon size="lg" icon={faCamera} className="menu-item-camera" />
                                                    </Box>
                                                    <span>Synchroniser avec le planning</span>
                                                </Box>
                                            </div>}
                                    </div>)
                                }} />)}
                            <MenuItem render={(props: MenuItemProps) => {
                                const hasAnotherFlowsSelected = transportSellFlowsVehiclesListAll.some(x => x.entity === TransportSaleEntityLightModel.transportFlow && x.checked === true
                                    && ((contextMenuRow?.preInvoiceId && x.preInvoiceId !== contextMenuRow?.preInvoiceId) || (!contextMenuRow?.preInvoiceId && `${x.transportFlowId}-${x.planningId}` !== `${contextMenuRow?.transportFlowId}-${contextMenuRow?.planningId}`)));
                                const title = hasAnotherFlowsSelected
                                    ? "Impossible de faire cette action: Une ou plusieurs ligne(s) sélectionnée(s)"
                                    : !contextMenuRow?.preInvoiceId
                                        ? "Seules les BLs synchronisés et non intégrés seront supprimés"
                                        : contextMenuRow?.sentStatus === PreInvoiceSentStatusLightModel.successfully
                                            ? "Les BL intégrés ne peuvent pas être supprimés"
                                            : "";

                                return (<div onClick={event => event.stopPropagation()} className="menu-item-div">
                                    {(hasAnotherFlowsSelected
                                        || !contextMenuRow?.preInvoiceId
                                        || contextMenuRow?.sentStatus === PreInvoiceSentStatusLightModel.successfully) ?
                                        <Tooltip title={title} placement="right">
                                            <Box display="flex" flexDirection="row" width="100%" className="menu-item-disabled">
                                                <Box className="menu-item-icon">
                                                    <FontAwesomeIcon size="lg" icon={faTrash} />
                                                </Box>
                                                <span>Supprimer</span>
                                            </Box>
                                        </Tooltip>
                                        :
                                        <div onClick={(e) => this.removePreInvoice()} >
                                            <Box display="flex" flexDirection="row" width="100%">
                                                <Box className="menu-item-icon">
                                                    <FontAwesomeIcon size="lg" icon={faTrash} className="menu-item-trash" />
                                                </Box>
                                                <span>Supprimer</span>
                                            </Box>
                                        </div>}
                                </div>)
                            }} />
                            <MenuItem render={(props: MenuItemProps) => {
                                const hasAnotherFlowsSelected = transportSellFlowsVehiclesListAll.some(x => x.entity === TransportSaleEntityLightModel.transportFlow && x.checked === true
                                    && ((contextMenuRow?.preInvoiceId && x.preInvoiceId !== contextMenuRow?.preInvoiceId) || (!contextMenuRow?.preInvoiceId && `${x.transportFlowId}-${x.planningId}` !== `${contextMenuRow?.transportFlowId}-${contextMenuRow?.planningId}`)));
                                const title = hasAnotherFlowsSelected
                                    ? "Impossible de faire cette action: Une ou plusieurs ligne(s) sélectionnée(s)"
                                    : !contextMenuRow?.preInvoiceId
                                        ? "Impossible de faire cette action: Ce BL de vente n'est pas encore synchronisé avec le planning"
                                        : contextMenuRow?.sentStatus === PreInvoiceSentStatusLightModel.successfully
                                            ? "Impossible de faire cette action: Ce BL de vente a déjà été intégré"
                                            : inputSearchVehiclesValue?.length > 0
                                                ? "Impossible de faire cette action: un filtre est actif"
                                                : "";

                                return (<div onClick={event => event.stopPropagation()} className="menu-item-div">
                                    {(hasAnotherFlowsSelected || contextMenuRow?.sentStatus === PreInvoiceSentStatusLightModel.successfully || !contextMenuRow?.preInvoiceId) ?
                                        <Tooltip title={title} placement="right">
                                            <Box display="flex" flexDirection="row" width="100%" className="menu-item-disabled">
                                                <Box className="menu-item-icon">
                                                    <img src={imgSendZephyrGray} width="20" height="20" />
                                                </Box>
                                                <span>Envoyer vers Zéphyr</span>
                                            </Box>
                                        </Tooltip>
                                        :
                                        <div onClick={(e) => this.sendIntegrationPreInvoices()} >
                                            <Box display="flex" flexDirection="row" width="100%">
                                                <Box className="menu-item-icon">
                                                    <img src={imgSendZephyr} width="20" height="20" />
                                                </Box>
                                                <span>Envoyer vers Zéphyr</span>
                                            </Box>
                                        </div>}
                                </div>)
                            }} />
                            {!contextMenuRow?.regularizationNumber &&
                                (<MenuItem render={(props: MenuItemProps) => {
                                    const hasAnotherFlowsSelected = transportSellFlowsVehiclesListAll.some(x => x.entity === TransportSaleEntityLightModel.transportFlow && x.checked === true
                                        && ((contextMenuRow?.preInvoiceId && x.preInvoiceId !== contextMenuRow?.preInvoiceId) || (!contextMenuRow?.preInvoiceId && `${x.transportFlowId}-${x.planningId}` !== `${contextMenuRow?.transportFlowId}-${contextMenuRow?.planningId}`)));
                                    const title = hasAnotherFlowsSelected
                                        ? "Impossible de faire cette action: Une ou plusieurs ligne(s) sélectionnée(s)"
                                        : !contextMenuRow?.preInvoiceId
                                            ? "Régularisation impossible car le flux n'est pas synchronisé avec le planning"
                                            : contextMenuRow?.sentStatus !== PreInvoiceSentStatusLightModel.successfully
                                                ? "Régularisation impossible car le flux n'est pas intégré"
                                                : contextMenuRow?.hasPendingRegularization
                                                    ? "Régularisation impossible car il existe une régularisation non intégrée sur le flux concerné"
                                                    : "";

                                    return (<div onClick={event => event.stopPropagation()} className="menu-item-div">
                                        {(hasAnotherFlowsSelected || contextMenuRow?.hasPendingRegularization || !contextMenuRow?.preInvoiceId || contextMenuRow?.sentStatus !== PreInvoiceSentStatusLightModel.successfully) ?
                                            <Tooltip title={title} placement="right">
                                                <Box display="flex" flexDirection="row" width="100%" className="menu-item-disabled">
                                                    <Box className="menu-item-icon menu-item-regul">
                                                        R
                                                    </Box>
                                                    <span>Régulariser</span>
                                                </Box>
                                            </Tooltip>
                                            : <div onClick={(e) => this.handleClickOpenDialogRegularization(contextMenuRow.preInvoiceId, contextMenuRow.businessId)} >
                                                <Box display="flex" flexDirection="row" width="100%">
                                                    <Box className="menu-item-icon menu-item-regul menu-item-regul-enabled">
                                                        R
                                                    </Box>
                                                    <span>Régulariser</span>
                                                </Box>
                                            </div>}
                                    </div>)
                                }} />)}
                            {!contextMenuRow?.regularizationNumber &&
                                (<MenuItem render={(props: MenuItemProps) => {
                                    const hasAnotherFlowsSelected = transportSellFlowsVehiclesListAll.some(x => x.entity === TransportSaleEntityLightModel.transportFlow && x.checked === true
                                        && ((contextMenuRow?.preInvoiceId && x.preInvoiceId !== contextMenuRow?.preInvoiceId) || (!contextMenuRow?.preInvoiceId && `${x.transportFlowId}-${x.planningId}` !== `${contextMenuRow?.transportFlowId}-${contextMenuRow?.planningId}`)));
                                    const title = hasAnotherFlowsSelected
                                        ? "Impossible de faire cette action: Une ou plusieurs ligne(s) sélectionnée(s)"
                                        : contextMenuRow?.sentStatus === PreInvoiceSentStatusLightModel.successfully
                                            ? "Impossible de modifier le Flux car le BL de vente est intégré dans Zéphyr"
                                            : contextMenuRow?.flowStatus === "Finished"
                                                ? "Impossible de modifier un Flux au statut Terminé"
                                                : "";

                                    return (<div onClick={event => event.stopPropagation()} className="menu-item-div">
                                        {(hasAnotherFlowsSelected || contextMenuRow?.sentStatus === PreInvoiceSentStatusLightModel.successfully || contextMenuRow?.flowStatus === "Finished") ?
                                            <Tooltip title={title} placement="right">
                                                <Box display="flex" flexDirection="row" width="100%" className="menu-item-disabled">
                                                    <Box className="menu-item-icon">
                                                        <FontAwesomeIcon size="lg" icon={faPen} />
                                                    </Box>
                                                    <span>Modifier et synchroniser le Flux</span>
                                                </Box>
                                            </Tooltip>
                                            :
                                            <div onClick={(e) => this.handleEditFlow()} >
                                                <Box display="flex" flexDirection="row" width="100%">
                                                    <Box className="menu-item-icon">
                                                        <FontAwesomeIcon size="lg" icon={faPen} className="menu-item-pen" />
                                                    </Box>
                                                    <span>Modifier et synchroniser le Flux</span>
                                                </Box>
                                            </div>}
                                    </div>)
                                }} />)}
                            {!contextMenuRow?.regularizationNumber &&
                                (<MenuItem render={(props: MenuItemProps) => {
                                    const hasAnotherFlowsSelected = transportSellFlowsVehiclesListAll.some(x => x.entity === TransportSaleEntityLightModel.transportFlow && x.checked === true
                                        && ((contextMenuRow?.preInvoiceId && x.preInvoiceId !== contextMenuRow?.preInvoiceId) || (!contextMenuRow?.preInvoiceId && `${x.transportFlowId}-${x.planningId}` !== `${contextMenuRow?.transportFlowId}-${contextMenuRow?.planningId}`)));
                                    const title = hasAnotherFlowsSelected
                                        ? "Impossible de faire cette action: Une ou plusieurs ligne(s) sélectionnée(s)"
                                        : !contextMenuRow?.preInvoiceId
                                            ? "Seuls les prix des BLs synchronisés et non intégrés peuvent être copiés"
                                            : contextMenuRow?.sentStatus === PreInvoiceSentStatusLightModel.successfully
                                                ? "Les prix des BL intégrés ne peuvent pas être copiés"
                                                : "";

                                    return (<div onClick={event => event.stopPropagation()} className="menu-item-div">
                                        {(hasAnotherFlowsSelected
                                            || !contextMenuRow?.preInvoiceId
                                            || contextMenuRow?.sentStatus === PreInvoiceSentStatusLightModel.successfully) ?
                                            <Tooltip title={title} placement="right">
                                                <Box display="flex" flexDirection="row" width="100%" className="menu-item-disabled">
                                                    <Box className="menu-item-icon">
                                                        <FontAwesomeIcon size="lg" icon={faClone} />
                                                    </Box>
                                                    <span>Copier les "Prix de vente Flux" dans "Prix de vente à facturer"</span>
                                                </Box>
                                            </Tooltip>
                                            :
                                            <div onClick={(e) => this.copyFlowPriceIntoInvoicePrice()} >
                                                <Box display="flex" flexDirection="row" width="100%">
                                                    <Box className="menu-item-icon">
                                                        <FontAwesomeIcon size="lg" icon={faClone} className="menu-item-trash" />
                                                    </Box>
                                                    <span>Copier les "Prix de vente Flux" dans "Prix de vente à facturer"</span>
                                                </Box>
                                            </div>}
                                    </div>)
                                }} />)}
                        </Menu>
                    </ClickAwayListener>
                </Popup>
                <Dialog
                    disableEscapeKeyDown
                    aria-modal="false"
                    open={simpleDialog.isDialogOpened}
                    onClose={(_event, reason) => this.handleClickCloseDialog(reason)}
                    scroll="paper"
                    className="simple-dialog-regularization"
                    PaperComponent={PaperComponent}
                    aria-labelledby="draggable-dialog-title"
                >
                    <DialogTitle id="draggable-dialog-title">
                        {simpleDialog.dialogTitle}
                    </DialogTitle>
                    <DialogContent dividers>
                        {simpleDialog.popupContentComponent}
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => this.handleClickCloseDialog()} color="primary">
                            Annuler
                        </Button>
                        <Button onClick={this.CreateRegularizationPreInvoice} color="primary" disabled={!hasAnyRegularization}>
                            Confirmer
                        </Button>
                    </DialogActions>
                </Dialog>
                {
                    (contextMenuRow && isTransportFlowFormViewOpened) &&
                    <SimpleDialog isOpen={isTransportFlowFormViewOpened}
                        onClose={this.handleCloseTransportFlowForm}
                        dialogTitleComponent={<TransportFlowHeaderComponent mode={updateMode} flowBusinessId={contextMenuRow.transportFlowBusinessId} />}
                        closeIcon={true}
                        classNameVal="transport-flow-dialog"
                        headerBorder={true}
                        component={
                            <TransportFlowForm logisticsUnits={this.props.logisticsUnits}
                                chosenLogisticsUnitIds={this.props.logisticsUnitIds}
                                onClose={this.handleCloseTransportFlowForm}
                                onCloseAfterValidation={this.handleCloseTransportFlowFormAfterValidation}
                                mode={updateMode}
                                transportFlowId={contextMenuRow.transportFlowId}
                                flowBusinessId={contextMenuRow.transportFlowBusinessId}
                            />
                        }
                    />
                }
            </>;

        return ret;
    }
}
